import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  InputBase,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import {
  addDevices,
  deleteDevices,
  getDevicePool,
  getDevicePoolForSwap,
  getLicensesListInDeviceList,
  swapLicense,
  updateDevices,
} from "../../../database/user/devices";
import { getPond } from "../../../database/user/pond";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import DeviceList from "../../../components/device/DeviceList";
import {
  DEVICE_LICENSE,
  DEVICE_SC_20K,
  DEVICE_SC_4K,
  DEVICE_SP_1,
  M12,
  M3,
  NO,
  NONE,
  SLA,
  YES,
} from "../../../common/configs";
import { updateLicenseWithDevicePool } from "../../../database/user/licensing";
import { getCurrentTimestampInMillis, nanoTimeToDateTimeString } from "../../../common/utils";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function Devices() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [deviceList, setDeviceList] = useState([]);
  const [deviceList2, setDeviceList2] = useState([]);
  const [noOfDevices, setNoOfDevices] = useState(0);
  const [sortValue, setSortValue] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSwapModal, setIsSwapModal] = useState(false);
  const [lstSwapDevice, setLstSwapDevice] = useState([]);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [newStatus, setNewStatus] = useState();

  const [isDisable, setIsDisable] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [isNameErr, setIsNameErr] = useState(false);
  const [deviceType, setDeviceType] = useState(1);
  const [status, setStatus] = useState(3);
  const [deviceTypeList] = useState([
    { label: "Sincere Counter", value: 1 },
    { label: "Sincere Probe", value: 2 },
  ]);
  const [deviceStausList] = useState([
    { label: "Ok", value: 3 },
    { label: "Warning", value: 4 },
    { label: "Error", value: 5 },
  ]);
  const [pondList, setPondList] = useState([]);
  const [selectedPond, setSelectedPond] = useState("");
  const [selectPondIndex, setSelectPondIndex] = useState(null);
  const [isPondErr, setIsPondErr] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [objSelected, setObjSelected] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [licenseType, setLicenseType] = useState(DEVICE_LICENSE);
  const [objLicenseStatus, setObjLicenseStatus] = useState(null);
  const [licenseStatus, setLicenseStatus] = useState(NONE);
  const [showLicenseStatus, setShowLicenseStatus] = useState(false);
  const [duration, setDuration] = useState(M3);
  const [recurring, setRecurring] = useState(YES);
  const [licensesList, setLicensesList] = useState([]);

  const onCloseEdit = () => {
    setObjLicenseStatus(null)
    setLicenseStatus(NONE)
    setIsOpenModal(false)
    getDevicesListData()
    setIsView(false)
    setShowLicenseStatus(false)

    setIsEdit(false);
    setDeviceId(null);
    setDeviceName("");
    setDeviceType(1);
    setStatus(3);
    setSelectedPond("");
    setSelectPondIndex(null);
    setIsNameErr(false);
    setIsPondErr(false);
  }
  const onCloseSwap = () => {
    setObjLicenseStatus(null)
    setShowLicenseStatus(false)
    setLstSwapDevice([])
    getDevicesListData()
    setIsSwapModal(false)
  }

  const getSwapDeviceList = async () => {
    if (isSwapModal) {
      const data = await getDevicePoolForSwap(objSelected.type)
      setLstSwapDevice(data)
    }
  }

  useEffect(() => {
    getSwapDeviceList()
  }, [isSwapModal, objSelected])

  const getLicensesList = async () => {
    let data = await getLicensesListInDeviceList(objSelected.type, DEVICE_LICENSE);
    setLicensesList(data)
  }

  useEffect(() => {
    if (objSelected) {
      getLicensesList()
    }
  }, [objSelected, licenseType])
  useEffect(() => {
    if (deviceList2.length > 0) {
      setTimeout(() => {
        setSearch(searchText);
      }, 1000);
    }
  }, [searchText]);

  useEffect(() => {
    try {
      let mainData = deviceList2;
      if (search) {
        mainData = deviceList2.filter((val) => {
          return val.deviceId.toLowerCase().includes(search.toLowerCase());
        });
      }
      if (sortValue == 'all') {
        setDeviceList(mainData);
      } else {
        mainData = mainData.filter((ele) => {
          return ele.type == sortValue;
        });
      }
      setDeviceList(mainData);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [search, sortValue]);

  async function getDevicesListData() {
    try {
      const pId = localStorage.getItem("pId");
      let data = await getDevicePool(pId);

      let pondData = await getPond(pId);
      setPondList(pondData);
      setNoOfDevices(data.length);
      setDeviceList(data);
      setDeviceList2(data);
      setLoading(false);
    } catch (error) { }
  }
  useEffect(() => {
    getDevicesListData();
  }, []);

  const onAddEditSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let fullNameRegex = /^[a-zA-Z\s]+/gi;
    if (deviceName === "") {
      setIsDisable(false);
      return setIsNameErr(true);
    } else if (!fullNameRegex.test(deviceName)) {
      setIsDisable(false);
      return setIsNameErr(true);
    }
    if (deviceType == 2 && selectedPond === "") {
      setIsDisable(false);
      return setIsPondErr(true);
    }
    try {
      let pId = localStorage.getItem("pId");
      let ts = getCurrentTimestampInMillis();
      if (isEdit) {
        let data = {
          deviceName: deviceName,
          type: deviceType,
          status: status,
          lastCheckIn: ts,
        };
        if (selectPondIndex) {
          data = {
            ...data,
            pondId: pondList[selectPondIndex].id,
            pondName: pondList[selectPondIndex].pondName,
          };
        }
        await updateDevices(deviceId, data);
        let newArr = [];
        deviceList.map((ele) => {
          let obj = ele;
          if (deviceId === ele.id) {
            obj = { ...ele, ...data };
          }
          newArr.push(obj);
        });
        setDeviceList(newArr);
        setDeviceList2(newArr);
      } else {
        let data = {
          deviceNo: deviceList.length + 1,
          deviceName: deviceName,
          type: deviceType,
          pId: pId,
          status: status,
          lastCheckIn: ts,
          timestamp: ts,
        };
        if (selectPondIndex) {
          data = {
            ...data,
            pondId: pondList[selectPondIndex].id,
            pondName: pondList[selectPondIndex].pondName,
          };
        }
        let id = await addDevices(data);
        let newArr = deviceList;
        newArr.unshift({ ...data, id: id });
        setDeviceList(newArr);
        setDeviceList2(newArr);
        setNoOfDevices(noOfDevices + 1);
        // setOnline(online + 1);
      }
      setDeviceName("");
      setDeviceId(null);
      setDeviceType(1);
      setStatus(3);
      setSelectedPond("");
      setSelectPondIndex(null);
      setIsOpenModal(false);
      setIsDisable(false);
    } catch (error) {
      setIsDisable(false);
    }
  };

  const onSubmit = async () => {
    setIsDisable(true);

    const startDateTime = moment().toDate();
    const endDateTime = moment(startDateTime).clone().add(objLicenseStatus.duration === M3 ? 3 : 12, 'months').toDate()
    const objFinal = {
      deviceId: deviceId,
      id: objLicenseStatus.id,
      // startedOn: startDateTime,
      // endedOn: endDateTime
    }
    const licenseKey = objLicenseStatus.type === SLA ? 'slaLicId' : 'deviceLicId'
    const res = await updateLicenseWithDevicePool(objFinal, licenseKey)
    setIsDisable(false);
    onCloseEdit()
  }

  const onSwapSubmit = async () => {
    setIsSwapLoading(true)
    const res = await swapLicense(objSelected, objLicenseStatus)
    onCloseSwap()
    setIsSwapLoading(false)
  }

  const nameClassName = `field ${isNameErr ? "error" : ""}`;
  const pondClassName = `field ${isPondErr ? "error" : ""}`;

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("device.devices")}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation("/selectFarm");
      }}
    >
      <div
        className="column-block-css detail-css-block"
        style={{ marginTop: -20 }}
      >
        {/* ----- HEAD SEARCHES ----- */}
        <div className="head_container head_devices hedera_ui_css_block">
          <div className="hedera_flex_block_row">
            <div className="head_two select-main-block">
              <Box sx={{ minWidth: 260 }}>
                <Typography>{t("device.filter_by_status")}</Typography>
                <FormControl fullWidth>
                  <Select
                    className="Select-bg-color"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortValue}
                    onChange={async (e) => {
                      try {
                        setSortValue(e.target.value);
                      } catch (error) { }
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"all"}>{t("device.all")}</MenuItem>
                    <MenuItem value={DEVICE_SC_4K}>{t("device.sc_4k")}</MenuItem>
                    <MenuItem value={DEVICE_SC_20K}>{t("device.sc_20k")}</MenuItem>
                    <MenuItem value={DEVICE_SP_1}>{t("device.sp_1")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="head_one">
              <div
                style={{ width: "100%", marginTop: 0, marginLeft: 0 }}
                className="ui stackable two column grid"
              >
                <div
                  className="column"
                  style={{ width: "260px", padding: "0" }}
                >
                  <div
                    className="ui icon input input-css-block"
                    style={{ width: "260px" }}
                  >
                    <input
                      className="prompt"
                      type="text"
                      placeholder={t("device.search_device_name")}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setSearchText(e.target.value);
                        } else {
                          setSearchText(e.target.value);
                        }
                      }}
                      value={searchText}
                      style={{ border: "1px solid #ced4da", height: 43 }}
                    />
                    <i className="search icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----- DEVICES LIST TABLE ----- */}
        <div className="cards_main_block">
          <div className="inventory_table_wrap">
            <div className="table-responsive cm_table_responsive">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div id={`load`} className="loader" />
                </div>
              ) : deviceList.length > 0 ? (
                <table className="cm_table fold-table">
                  <thead className="cm_thead">
                    <tr className="cm_thead_list">
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("device.device-name")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("device.device_type")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.sla_lic_duration")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.device_lic_duration")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.license_status")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.sla_status")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.sla_status_expiry")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.device_license_expiry")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.backUp_device")}</h6>
                        </div>
                      </th>
                      {/* <th className="th_list cm_name"></th> */}
                      <th className="th_list cm_name"></th>
                      {/* <th className="th_list cm_name"></th> */}
                    </tr>
                  </thead>
                  <tbody className="cm_tbody">
                    {deviceList.map((val, index) => {
                      return (
                        <>
                          <tr key={Math.random()} className="cm_tr view">
                            <DeviceList
                              val={val}
                              onClickCard={() => { }}
                              onClickMenu={() => {
                                setDeviceId(
                                  val.id === deviceId ? null : val.id
                                );
                              }}
                              openId={deviceId}
                              onClickEdit={() => {

                                setIsEdit(true);
                                setDeviceId(val.id);
                                setDeviceName(val.deviceName);
                                setDeviceType(val.type);
                                setStatus(val.status);
                                setSelectedPond(val.pondId);
                                setIsOpenModal(true);
                                setObjSelected(val)
                                setLicenseType(val.deviceLicId ? SLA : DEVICE_LICENSE);
                                if (val.slaLic || val.deviceLicId) {
                                  setObjLicenseStatus(val.slaLic)
                                  setIsView(true)
                                }
                              }}
                              onClickDelete={() => {
                                setDeviceId(val.id);
                                setIsDeleteModal(true);
                              }}

                              onSwap={() => {
                                setObjSelected(val)
                                setIsSwapModal(true)
                              }}
                            />
                          </tr>
                          <tr className="cm_hi"></tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                    {t("device.you_dont_have_device_please_add_device")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* ----- ADD/EDIT MODAL ----- */}
        {objSelected &&
          <Modal
            open={isOpenModal}
            onClose={() => onCloseEdit()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle} className="add-Pond-location">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: colors.slatery_blue,
                  justifyContent: "space-between",
                  padding: 8,
                  position: "sticky",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
                className="hiding-pond-css"
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "600",
                    marginLeft: 1,
                  }}
                >
                  {isView ? `${t("licenseDetails.view_license")}` : `${t("licenseDetails.assign_license")}`}
                </Typography>
                <i
                  className="x icon"
                  style={{ color: "white", fontSize: 22 }}
                  onClick={() => {
                    if (isDisable) {
                    } else {
                      onCloseEdit()
                    }
                  }}
                />
              </div>

              <div style={{ height: "auto", overflow: "auto" }}>
                <form
                  className="ui form container"
                  style={{
                    marginTop: 13,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 10,
                  }}
                >
                  {/* -- DEVICE NAME -- */}
                  {objSelected.slaLic &&
                    <div style={{ marginTop: '20px' }}>
                      <h5 className="common_title_text" style={{ margin: '10px 0px' }}>{t("licenseDetails.sla")}</h5>
                      <div style={{ marginLeft: '20px' }}>
                        <div className="common_title_css">
                          <h5 className="common_title_text">{t("licenseDetails.license_name")}:-</h5>
                          <span className="common_title_description">{objSelected.slaLic?.name}</span>
                        </div>
                        <div>
                          <div className="common_title_css">
                            <h5 className="common_title_text">{t("licenseDetails.duration")}:-</h5>
                            <span className="common_title_description">{objSelected.slaLic.duration}</span>
                          </div>
                          <div className="common_title_css">
                            <h5 className="common_title_text">{t("licenseDetails.recurring")}:-</h5>
                            <span className="common_title_description">{objSelected.slaLic.isRecurring ? t("ponds.yes") : NO}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {objSelected.deviceLic &&
                    <div style={{ marginTop: '20px' }}>
                      <h5 className="common_title_text" style={{ margin: '10px 0px' }}>{t("licenseDetails.device_license")}</h5>
                      <div style={{ marginLeft: '20px' }}>
                        <div className="common_title_css">
                          <h5 className="common_title_text">{t("licenseDetails.license_name")}:-</h5>
                          <span className="common_title_description">{objSelected.deviceLic?.name}</span>
                        </div>
                        <div>
                          <div className="common_title_css">
                            <h5 className="common_title_text">{t("licenseDetails.duration")}:-</h5>
                            <span className="common_title_description">{objSelected.deviceLic.duration}</span>
                          </div>
                          <div className="common_title_css">
                            <h5 className="common_title_text">{t("licenseDetails.recurring")}:-</h5>
                            <span className="common_title_description">{objSelected.deviceLic.isRecurring ? t("ponds.yes") : NO}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }


                  {/* <div className="common_title_css">
                    <h5 className="common_title_text">{t("device.device_name")}:-</h5>
                    <span className="common_title_description">{objSelected?.deviceId}</span>
                  </div>

                  <div className="common_title_css">
                    <h5 className="common_title_text">{t("device.device_type")}:-</h5>
                    <span className="common_title_description">{objSelected?.type}</span>
                  </div> */}

                  {
                    objSelected.swappedWithDeviceId &&
                    <div style={{ marginTop: '20px' }}>
                      <h5 className="common_title_text" style={{ margin: '10px 0px' }}>{t("licenseDetails.swap_device")}</h5>
                      <div style={{ marginLeft: '20px' }}>
                        {objSelected.swappedWithDeviceId &&
                          <div className="common_title_css">
                            <h5 className="common_title_text">{t("licenseDetails.swappedWithDeviceId")}:-</h5>
                            <span className="common_title_description">{objSelected?.swappedWithDeviceId}</span>
                          </div>}


                        {objSelected.swappedOn &&
                          <div className="common_title_css">
                            <h5 className="common_title_text">{t("licenseDetails.swappedOn")}:-</h5>
                            <span className="common_title_description">{nanoTimeToDateTimeString(objSelected?.swappedOn, "MMM Do, YYYY")}</span>
                          </div>}
                      </div>
                    </div>
                  }
                  {!isView ? !objSelected.deviceLic && !objSelected.swappedWithDeviceId && <div>
                    <Box>
                      <h5 className="common_title_text" style={{ marginBottom: 5 }}>{t("licenseDetails.select_license_to_attach_with_this_device")}</h5>
                      <FormControl fullWidth style={{ marginBottom: 12 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="licenseStatus"
                          value={licenseStatus}
                          onChange={(e) => {
                            setLicenseStatus(e.target.value)
                            const objTmp = licensesList.find((obj) => e.target.value === obj.id)
                            setObjLicenseStatus(objTmp)
                          }}
                          input={<BootstrapInput />}
                        >
                          {licensesList.map(
                            (item, index) => {
                              return (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </div> : null}

                  {/* {objLicenseStatus &&
                    <div>
                      <div className="common_title_css">
                        <h5 className="common_title_text">{t("licenseDetails.duration")}:-</h5>
                        <span className="common_title_description">{objLicenseStatus.duration}</span>
                      </div>
                      <div className="common_title_css">
                        <h5 className="common_title_text">{t("licenseDetails.recurring")}:-</h5>
                        <span className="common_title_description">{objLicenseStatus.isRecurring ? YES : NO}</span>
                      </div>
                    </div>
                  } */}

                  {/* -- ASSOCIATED POND -- */}
                  {deviceType == 2 && (
                    <div className={pondClassName}>
                      <label>{t("device.associated_pond")}</label>
                      <select
                        className="ui fluid dropdown"
                        name="associatedPond"
                        onChange={(e) => {
                          if (isPondErr) {
                            setIsPondErr(false);
                          }
                          if (e.target.value === "") {
                            setSelectPondIndex(null);
                          } else {
                            setSelectPondIndex(e.target.selectedIndex - 1);
                          }
                          setSelectedPond(e.target.value);
                        }}
                        value={selectedPond}
                      >
                        <option value={""}>{t("device.none")}</option>
                        {pondList.length > 0 &&
                          pondList.map((ele) => {
                            return (
                              <option key={ele.id} value={ele.id}>
                                {ele.pondName}
                              </option>
                            );
                          })}
                      </select>
                      {isPondErr && (
                        <div className="ui pointing red basic label">
                          {selectedPond === "" && pondList.length > 0
                            ? t("device.associated_pond_msg_1")
                            : t("device.associated_pond_msg_2")}
                        </div>
                      )}
                    </div>
                  )}

                  {/* -- BUTTON -- */}
                  {!isView &&
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <LoadingButton
                        onClick={onSubmit}
                        loading={isDisable}
                        loadingPosition="start"
                        startIcon={<Save />}
                        variant="contained"
                        sx={{ backgroundColor: colors.slatery_blue }}
                        disabled={licenseStatus === NONE}
                        style={{ opacity: licenseStatus === NONE ? 0.5 : 1 }}
                      >
                        {t("ponds.save")}
                      </LoadingButton>
                    </div>
                  }
                </form>
              </div>
            </Box>
          </Modal>
        }

        {/* ----- DELETE MODAL ----- */}
        <Modal
          open={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={[boxStyle, { width: 400 }]}
            className="logout-css-modal-block"
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                paddingTop: 2,
                textAlign: "center",
                marginBottom: 2,
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              {t("ponds.are_you_sure_you_want_to_delete_this")}
            </Typography>

            <Divider />
            <div className="logout-button-block">
              <Typography
                sx={textStyle}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setDeviceId(null);
                    setIsDeleteModal(false);
                  }
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <div
                style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
              />
              <Typography
                className="delete_bg_color"
                sx={[textStyle, { color: "red" }]}
                onClick={async () => {
                  if (isDisable) {
                  } else {
                    try {
                      setIsDisable(true);
                      let res = await deleteDevices(deviceId);
                      if (res) {
                        const newArr = deviceList.filter((ele) => {
                          return ele.id !== deviceId;
                        });
                        if (newArr.length <= 0) {
                          setNoOfDevices(0);
                        } else {
                          setNoOfDevices(noOfDevices - 1);
                        }
                        setDeviceList(newArr);
                        setDeviceList2(newArr);
                        setDeviceId(null);
                        setIsDeleteModal(false);
                        setIsDisable(false);
                      } else {
                        alert("Something went wrong, Please try again.");
                        setIsDisable(false);
                      }
                    } catch (error) {
                      setIsDisable(false);
                    }
                  }
                }}
              >
                {t("ponds.delete")}
              </Typography>
            </div>
          </Box>
        </Modal>

        {/* SWAP MODAL */}
        <Modal
          open={isSwapModal}
          onClose={() => setIsSwapModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={[boxStyle, { width: 400 }]} className="add-Pond-location">
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.slatery_blue,
                justifyContent: "space-between",
                padding: 8,
                position: "sticky",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className="hiding-pond-css"
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: "600",
                  marginLeft: 1,
                }}
              >
                {t("licenseDetails.swap_device")}
              </Typography>
              <i
                className="x icon"
                style={{ color: "white", fontSize: 22 }}
                onClick={() => {
                  setIsSwapModal(false);
                }}
              />
            </div>
            <div style={{ padding: 15, paddingTop: 10 }}>
              <div className="common_title_css">
                <h5 className="common_title_text">{t("device.device_name")}:-</h5>
                <span className="common_title_description">{objSelected?.deviceId}</span>
              </div>
              <div className="common_title_css">
                <h5 className="common_title_text">{t("device.device_type")}:-</h5>
                <span className="common_title_description">{objSelected?.type}</span>
              </div>

              <div>
                <h5 className="common_title_text" style={{ marginBottom: 5 }}>{t("licenseDetails.select_backup_device")}</h5>
                <FormControl fullWidth style={{ marginBottom: 12 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="licenseStatus"
                    value={newStatus}
                    onChange={(e) => {
                      setNewStatus(e.target.value)
                      const objTmp = lstSwapDevice.find((obj) => e.target.value === obj.id)
                      setObjLicenseStatus(objTmp)
                    }}
                    input={<BootstrapInput />}
                  >
                    {lstSwapDevice.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.deviceId}>
                          {item.deviceId}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  onClick={onSwapSubmit}
                  loading={isSwapLoading}
                  loadingPosition="start"
                  startIcon={<Save />}
                  variant="contained"
                  sx={{ backgroundColor: colors.slatery_blue }}
                  disabled={!newStatus}
                  style={{opacity: !newStatus? 0.5 : 1}}
                >
                  {t("device.swap")}
                </LoadingButton>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </ResponsiveDrawer>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default Devices;
