import {
  Alert,
  Box,
  Divider,
  FormControl,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  TextField,
  Typography,
  styled,
  Button,
  InputBase,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import {
  deleteDataFromDb,
  getPondRecord,
  updateRecord,
  createLinkRecords,
  updateLinkRecords,
  getLinkRecords,
} from "../../../database/user/pond";
import moment from "moment-timezone";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import imagePath from "../../../common/imagePath";
import colors from "../../../common/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Save, Create, CopyAll, Done } from "@mui/icons-material";
import no_alarms_found from "../../../images/no_alarms_found.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LinkIcon from "@mui/icons-material/Link";
import PondRecodeMap from "../map/PondRecodeMap";
import LightBox from "../../../common/LightBox";
import {
  getCurrentTimestamp,
  getTimestampWithFormatDate,
  getCurrentTimestampInMillis,
} from "../../../common/utils";
import { UTC } from "../../../common/configs";
import { CYCLE_ID, FIRESTORE_POND_RECORD } from "../../../database/config";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function PondRecords() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [recordList, setRecordList] = useState([]);
  const [monitoringType, setMonitoringType] = useState("HEALTH");
  const [isLoader, setIsLoader] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState(null);
  const [cycleId, setCycleId] = useState();
  const [picture, setPicture] = useState(null);
  const [isOpenPic, setIsOpenPic] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCreateModal, setIsCreateLinkModal] = useState(false);
  const [shrimpStatusList] = useState([
    { value: "Yes", label: t("ponds.yes") },
    { value: "No", label: t("ponds.no") },
    { value: "Check Needed", label: t("ponds.check_needed") },
  ]);
  const [shrimpSickStatus, setShrimpSickStatus] = useState("No");
  const [shrimpWeight, setShrimpWeight] = useState("");
  const [shrimpAmount, setShrimpAmount] = useState("");
  const [days, setDays] = useState(14);
  const [typeList] = useState([
    { value: "Counter", label: t("ponds.Counter") },
    { value: "Phone", label: t("ponds.Phone") },
    { value: "Probe", label: t("ponds.Probe") },
  ]);
  const [type, setType] = useState("Counter");
  const [comment, setComment] = useState("");
  const [showError, setShowError] = useState({
    isShrimpWeight: false,
    isShrimpAmount: false,
    isDays: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isShowAppBar, setIsShowAppBar] = useState(true);
  const [isViewMore, setIsViewMore] = useState(true);
  const [linkRecordId, setLinkRecordId] = useState("");
  const [recordLink, setRecordLink] = useState("");
  const [isCopied, setCopied] = useState(false);
  const [linkId, setLinkId] = useState("");
  const [modalData, setModalData] = useState(null);
  const [openModalData, setOpenModalData] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [showLight, setShowLight] = useState(null);
  const [imageArrayIndex, setImageArrayIndex] = useState();

  const increment = () => {
    setDays(function (prevCount) {
      prevCount = Number(prevCount);
      if (prevCount >= 90) {
        return prevCount;
      }
      return (prevCount += 1);
    });
  };

  const decrement = () => {
    setDays(function (prevCount) {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
  };

  const getRecords = async (monitoringType) => {
    try {
      setIsLoader(true);
      const isNurseryPond =
        window.history.state.usr.cardData &&
        window.history.state.usr.cardData.isNursery
          ? true
          : false;
      let data = await getPondRecord(
        window.history.state.usr.cycleId,
        null,
        monitoringType,
        15,
        CYCLE_ID,
        isNurseryPond
      );
      if (data.length > 0) {
        let images = [];
        for (const val of data) {
          if (val.topImage) {
            images.push({
              ...val,
              imageType: t("home.top"),
              src: val.topImage,
              thumbnail: val.topImage,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
            images.push({
              ...val,
              imageType: t("home.side"),
              src: val.sideImage,
              thumbnail: val.sideImage,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
          } else {
            images.push({
              ...val,
              imageType: t("home.top"),
              src: val.picture,
              thumbnail: val.picture,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
          }
        }
        const chunkSize = 2; // Set the number of objects you want in each sub-array
        const imageJoinArr = [];

        for (let i = 0; i < images.length; i += chunkSize) {
          imageJoinArr.push(images.slice(i, i + chunkSize));
        }

        setImagesList(imageJoinArr);
      } else {
        setImagesList([]);
      }
      setRecordList(data);
      if (data.length < 15) {
        setIsViewMore(false);
      }
      setIsLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    setIsViewMore(true);
    getRecords(monitoringType);
  }, [monitoringType]);

  const onCreateLinkClicked = async (id) => {
    setIsLoader1(true);
    const linkData = await getLinkRecords(id);
    if (linkData) {
      setLinkId(linkData.id);
      const expireOn = getCurrentTimestampInMillis();
      setIsLoader1(false);
      if (linkData.expireOn > expireOn) {
        setRecordLink(linkData.link);
        setDays(linkData.days ? Number(linkData.days) : 14);
      } else {
        setDays(14);
        setRecordLink(null);
        setCopied(false);
        setIsLoader1(false);
      }
    } else {
      setDays(14);
      setRecordLink(null);
      setCopied(false);
      setIsLoader1(false);
    }
    setIsCreateLinkModal(true);
  };

  const createLink = async () => {
    if (recordLink) {
      setRecordLink(null);
      setCopied(false);
      return;
    }
    if (!linkRecordId) {
      return;
    }
    setIsDisable(true);
    if (days) {
      var expireOn = moment().add(Number(days), "days");
      let link = "http://localhost:3000";
      if (process.env.NODE_ENV == "production") {
        link = window.location.origin;
      }
      link = link + `/viewimages/${linkRecordId}`;
      const data = {
        expireOn: expireOn.valueOf(),
        link: link,
        type: "record",
        days: days,
        linkRecordId: linkRecordId,
      };

      let id = "";
      if (!linkId) {
        id = await createLinkRecords(data);
      } else {
        id = await updateLinkRecords(linkId, data);
      }

      if (id) {
        setLinkId(id);
        setRecordLink(id ? data.link : "");
      } else {
        return alert("Something went wrong. Please try again later.");
      }
      setIsDisable(false);
    } else {
      setShowError({ ...showError, isDays: true });
      setIsDisable(false);
    }
  };

  const onClickEdit = async () => {
    setIsDisable(true);
    try {
      if (shrimpWeight === "") {
        setShowError({ ...showError, isShrimpWeight: true });
        setIsDisable(false);
        return;
      }

      const userId = localStorage.getItem("uid");
      const data = {
        EditTimeStamp: Number(getCurrentTimestampInMillis()),
        EditByUserId: userId,
        edited: true,
        type: type,
        comment: comment,
        shrimpSickStatus: shrimpSickStatus,
        shrimpWeight: shrimpWeight,
        shrimpAmount: shrimpAmount,
      };
      const updated = await updateRecord(idForUpdate, data);
      // setRecordList([]);
      if (updated === null) {
        return alert("Something went wrong. Please try again later.");
      }
      let newArr = recordList.map((val) => {
        let obj = val;
        if (val.id === idForUpdate) {
          obj = { ...val, ...data };
        }
        return obj;
      });
      setRecordList(newArr);
      // await getRecords();
      setShrimpWeight("");
      setShrimpAmount("");
      setComment("");
      setIsOpenModal(false);
      setIsDisable(false);
    } catch (error) {
      setIsDisable(false);
    }
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (index) => {
    const currentExpandedRows = [...expandedRows];
    const isRowExpanded = currentExpandedRows.includes(index);
    if (isRowExpanded) {
      currentExpandedRows.splice(currentExpandedRows.indexOf(index), 1);
    } else {
      currentExpandedRows.push(index);
    }
    setExpandedRows(currentExpandedRows);
  };

  return (
    <div className={isLoader1 ? "overflow_block" : ""}>
      <ResponsiveDrawer
        isShowAppBar={isShowAppBar}
        headText={`${t("ponds.record_for_pond")}: ${
          window.history.state.usr.cardData.pondName
        }`}
        isHideToolBar={true}
        isBackArrow={true}
        onClickBack={() => {
          navigation(`/pond_detail`, {
            state: {
              cardData: window.history.state.usr.cardData,
            },
          });
        }}
      >
        {/* ----- HEAD SEARCHES ----- */}
        <div className="head_container head_container_records">
          <div className="head_two select-main-block">
            <Box sx={{ minWidth: 260 }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={monitoringType}
                  label={t("ponds.healthMonitoring")}
                  onChange={(e) => setMonitoringType(e.target.value)}
                  input={<BootstrapInput />}
                >
                  <MenuItem value={"HEALTH"}>
                    {t("ponds.healthMonitoring")}
                  </MenuItem>
                  <MenuItem value={"WEIGHT"}>
                    {t("ponds.weightMonitoring")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>

        {/* ----- TABLE HEALTH ----- */}
        {isLoader ? (
          <div
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        ) : recordList.length > 0 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              overflow: "auto",
              marginTop: 10,
            }}
          >
            <table
              className="ui celled table user-manage-tab"
              style={{ marginTop: 0 }}
            >
              <thead>
                <tr>
                  <th> </th>
                  <th style={{ textAlign: "center" }}>
                    {monitoringType == "HEALTH"
                      ? t("home.top")
                      : t("home.picture")}
                  </th>
                  {monitoringType == "HEALTH" && (
                    <th style={{ textAlign: "center" }}>{t("home.side")}</th>
                  )}
                  <th>{t("home.time")}</th>
                  {monitoringType == "HEALTH" && (
                    <th>{t("ponds.shrimpSickStatus")}</th>
                  )}
                  {monitoringType == "WEIGHT" && (
                    <th>{t("ponds.amountOfShrimp")}</th>
                  )}
                  {monitoringType == "WEIGHT" && (
                    <th>
                      {t("ponds.shrimpWeight")} {t("ponds.g")}
                    </th>
                  )}
                  {/* <th>{t("ponds.edited")}</th> */}
                  {/* <th>{t("home.type")}</th> */}
                  {/* <th>Status</th> */}
                  <th>{t("ponds.pond_name")}</th>
                  <th>{t("ponds.pond_cycle_name")}</th>
                  <th>{t("home.reported_by")}</th>
                  {/* <th>{t("ponds.id")}</th> */}
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {recordList.map((val, index) => {
                  const image = val.topImage ? val.topImage : val.picture;
                  return (
                    <React.Fragment>
                      <tr key={Math.random()}>
                        <td
                          onClick={() => toggleRow(index)}
                          className="arrow_set"
                          style={{ verticalAlign: "middle" }}
                        >
                          {expandedRows.includes(index) ? (
                            <div>
                              <svg
                                width="15"
                                height="10"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.99997 0.25C5.19887 0.25 5.38967 0.32901 5.53027 0.46967L9.53033 4.46967C9.82322 4.76256 9.82322 5.2374 9.53033 5.5303C9.23744 5.8232 8.76256 5.8232 8.46967 5.5303L4.99997 2.06066L1.53037 5.5303C1.23747 5.8232 0.762572 5.8232 0.469672 5.5303C0.176772 5.2374 0.176772 4.76256 0.469672 4.46967L4.46967 0.46967C4.61027 0.32901 4.80107 0.25 4.99997 0.25Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                width="15"
                                height="10"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.469672 0.469675C0.762572 0.176875 1.23747 0.176775 1.53037 0.469675L4.99997 3.93937L8.46967 0.469675C8.76256 0.176775 9.23744 0.176775 9.53033 0.469675C9.82322 0.762575 9.82322 1.23748 9.53033 1.53038L5.53037 5.53037C5.38967 5.67107 5.19887 5.75007 4.99997 5.75007C4.80107 5.75007 4.61037 5.67107 4.46967 5.53037L0.469672 1.53037C0.176772 1.23747 0.176772 0.762575 0.469672 0.469675Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                          )}
                        </td>
                        <td data-label={t("home.top")}>
                          <div style={style.container}>
                            {image && image !== "" ? (
                              <div>
                                <img
                                  src={image}
                                  style={{
                                    width: 25,
                                    height: 25,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    function findArrayIndex(arrays, target) {
                                      let indexID = -1;
                                      arrays.findIndex((innerArray, i) => {
                                        if (
                                          innerArray.some(
                                            (obj) => obj.id == target.id
                                          )
                                        ) {
                                          indexID = i;
                                          return true;
                                        }
                                        return false;
                                      });
                                      return indexID;
                                    }
                                    const arrayIndex = findArrayIndex(
                                      imagesList,
                                      val
                                    );
                                    setImageArrayIndex(arrayIndex);

                                    var index = imagesList[
                                      arrayIndex
                                    ].findIndex(
                                      (image) => image.src == val.topImage
                                    );
                                    setShowLight({
                                      startIndex: index,
                                    });
                                    setPicture(image);
                                    setIsShowAppBar(false);
                                    setIsOpenPic(true);
                                  }}
                                />
                                {isOpenPic && (
                                  <LightBox
                                    images={imagesList[imageArrayIndex].map(
                                      (img) => ({
                                        url: img.src,
                                        title: img.caption,
                                      })
                                    )}
                                    startIndex={showLight.startIndex}
                                    onClose={() => {
                                      setIsOpenPic(false);
                                      setIsShowAppBar(true);
                                    }}
                                  />
                                  // <Lightbox
                                  //   mainSrc={picture ? picture : imagePath.noImg}
                                  //   onCloseRequest={() => {
                                  //     setIsOpenPic(false);
                                  //     setIsShowAppBar(true);
                                  //   }}
                                  // />
                                )}
                              </div>
                            ) : (
                              <img
                                src={imagePath.noImg}
                                style={{
                                  width: 25,
                                  height: 25,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        </td>
                        {monitoringType == "HEALTH" && (
                          <td data-label={t("home.side")}>
                            <div style={style.container}>
                              {val.sideImage && val.sideImage !== "" ? (
                                // <OverlayTrigger
                                //   trigger="click"
                                //   onToggle={(nextshow) => {
                                //     setPicture(val.picture);
                                //   }}
                                //   placement="right"
                                //   overlay={popover}
                                // >
                                //   <img
                                //     src={val.picture}
                                //     style={{ width: 25, height: 25 }}
                                //   />
                                // </OverlayTrigger>
                                <div>
                                  <img
                                    src={val.sideImage}
                                    style={{
                                      width: 25,
                                      height: 25,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      function findArrayIndex(arrays, target) {
                                        let indexID = -1;
                                        arrays.findIndex((innerArray, i) => {
                                          if (
                                            innerArray.some(
                                              (obj) => obj.id == target.id
                                            )
                                          ) {
                                            indexID = i;
                                            return true;
                                          }
                                          return false;
                                        });
                                        return indexID;
                                      }
                                      const arrayIndex = findArrayIndex(
                                        imagesList,
                                        val
                                      );
                                      setImageArrayIndex(arrayIndex);

                                      var index = imagesList[
                                        arrayIndex
                                      ].findIndex(
                                        (image) => image.src == val.sideImage
                                      );
                                      setShowLight({
                                        startIndex: index,
                                      });
                                      setPicture(val.sideImage);
                                      setIsShowAppBar(false);
                                      setIsOpenPic(true);
                                    }}
                                  />
                                  {isOpenPic && (
                                    <LightBox
                                      images={imagesList[imageArrayIndex].map(
                                        (img) => ({
                                          url: img.src,
                                          title: img.caption,
                                        })
                                      )}
                                      startIndex={showLight.startIndex}
                                      onClose={() => {
                                        setIsOpenPic(false);
                                        setIsShowAppBar(true);
                                      }}
                                    />
                                    // <Lightbox
                                    //   mainSrc={
                                    //     picture ? picture : imagePath.noImg
                                    //   }
                                    //   onCloseRequest={() => {
                                    //     setIsOpenPic(false);
                                    //     setIsShowAppBar(true);
                                    //   }}
                                    // />
                                  )}
                                </div>
                              ) : (
                                <img
                                  src={imagePath.noImg}
                                  style={{ width: 25, height: 25 }}
                                />
                              )}
                            </div>
                          </td>
                        )}
                        <td
                          data-label={t("home.time")}
                          style={{ verticalAlign: "middle" }}
                        >
                          {getTimestampWithFormatDate(
                            val.recordDate,
                            "DD-MM-YYYY @ hh:mma"
                          )}
                        </td>
                        {monitoringType == "HEALTH" && (
                          <td
                            data-label={t("ponds.shrimpSickStatus")}
                            style={{ verticalAlign: "middle" }}
                          >
                            {val.shrimpSickStatus
                              ? val.shrimpSickStatus
                              : "N/A"}
                          </td>
                        )}
                        {monitoringType == "WEIGHT" && (
                          <td
                            data-label={t("ponds.amountOfShrimp")}
                            style={{ verticalAlign: "middle" }}
                          >
                            {val.shrimpAmount ? val.shrimpAmount : 0}
                          </td>
                        )}
                        {monitoringType == "WEIGHT" && (
                          <td
                            data-label={t("ponds.shrimpWeight")}
                            style={{ verticalAlign: "middle" }}
                          >
                            {val.shrimpWeight ? val.shrimpWeight : 0}
                          </td>
                        )}
                        {/* <td data-label={t("ponds.edited")} style={{verticalAlign: 'middle'}}>{val.edited ? "Yes" : "No"}</td> */}
                        {/* <td
                    data-label={t("home.type")}
                    style={{ verticalAlign: "middle" }}
                  >
                    {val.type}
                  </td> */}
                        {/* <td data-label="Status">{val.status}</td> */}
                        <td>{val.pondName ? val.pondName : "-"}</td>
                        <td>{val.cycleName ? val.cycleName : "-"}</td>
                        <td>{val.reportedUser ? val.reportedUser : "-"}</td>
                        {/* <td
                    data-label={t("ponds.id")}
                    style={{ verticalAlign: "middle" }}
                  >{`${val.id ? val.id : val.id
                    }`}</td> */}
                        <td>
                          <div
                            className="farm-svg-css pond_record_icon_set"
                            style={style.container}
                          >
                            <div className="look_icon_css orange_icon_eye">
                              <LinkIcon
                                onClick={() => {
                                  if (val.id != linkRecordId) {
                                    setLinkId(null);
                                    setRecordLink(null);
                                    setCopied(false);
                                  }
                                  setLinkRecordId(val.id);
                                  onCreateLinkClicked(val.id);
                                }}
                              />
                            </div>
                            <div
                              className="look_icon_css blue_icon_eye"
                              // onClick={() => {
                              //   setModalData(val);
                              // }}
                              onClick={() => {
                                setModalData(val);
                                setOpenModalData(true);
                              }}
                              style={{ marginRight: 8 }}
                            >
                              <RemoveRedEyeIcon />
                            </div>
                            <div className="look_icon_css green_icon_eye">
                              <EditOutlinedIcon
                                onClick={() => {
                                  setShrimpSickStatus(val.shrimpSickStatus);
                                  setShrimpWeight(`${val.shrimpWeight}`);
                                  setShrimpAmount(`${val.shrimpAmount}`);
                                  setType(val.type);
                                  setComment(val.comment);
                                  setIdForUpdate(val.id);
                                  setIsOpenModal(true);
                                }}
                              />
                            </div>
                            <div
                              className="look_icon_css red_icon_eye "
                              onClick={() => {
                                setIdForUpdate(val.id);
                                setCycleId(val.cycleId);
                                setDeleteModal(true);
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.5 5H4.16667H17.5"
                                  stroke="#EF5350"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008"
                                  stroke="#EF5350"
                                  strokeWidth="1.4"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M8.3335 9.16675V14.1667"
                                  stroke="#EF5350"
                                  strokeWidth="1.4"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M11.6665 9.16675V14.1667"
                                  stroke="#EF5350"
                                  strokeWidth="1.4"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {expandedRows.includes(index) && (
                        <tr>
                          <td
                            colSpan={9}
                            data-label={t("home.time")}
                            style={{ verticalAlign: "middle" }}
                          >
                            <table class="table expanded_rows">
                              <thead>
                                <tr>
                                  <th scope="col">{t("ponds.comment")}</th>
                                  <th scope="col">{t("home.reported_by")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    data-label={t("ponds.comment")}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    {val.comment ? val.comment : "-"}
                                  </td>
                                  <td
                                    data-label={t("home.reported_by")}
                                    style={{ verticalAlign: "middle" }}
                                  >{`${
                                    val.reportedUser
                                      ? val.reportedUser
                                      : val.reportedBy
                                  }`}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "82vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            className="no-alarms-found-css"
          >
            <img src={no_alarms_found} alt="no_alarms_found image" />
            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
              {t("ponds.records_not_found")}
            </Typography>
          </div>
        )}
        {/* ----- VIEW MORE (PAGINATION) ----- */}
        {isViewMore && recordList.length > 0 && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Typography
              sx={{
                cursor: "pointer",
                color: colors.slatery_blue,
                fontSize: 16,
                fontWeight: "600",
                padding: 1,
              }}
              onClick={async () => {
                if (isDisable) {
                } else {
                  setIsDisable(true);
                  try {
                    let data = await getPondRecord(
                      window.history.state.usr.cycleId,
                      recordList[recordList.length - 1].recordDate,
                      15
                    );
                    if (data.length > 0) {
                      setRecordList(recordList.concat(data));
                      if (data.length <= 15) {
                        setIsViewMore(false);
                      }
                    } else {
                      setIsViewMore(true);
                    }
                    setIsDisable(false);
                  } catch (error) {
                    setIsDisable(false);
                  }
                }
              }}
            >
              {t("ponds.view_more")}
            </Typography>
          </div>
        )}

        {/* ----- EDIT MODAL ----- */}
        <Modal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle} className="add-Pond-location">
            {/* <div style={{ height: "600px", overflow: "auto" }}> */}
            <div
              className="hiding-pond-css"
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.slatery_blue,
                justifyContent: "space-between",
                padding: 8,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: "600",
                  marginLeft: 1,
                }}
              >
                {t("ponds.edit_record")}
              </Typography>
              <i
                className="x icon"
                style={{ color: "white", fontSize: 22 }}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setIsOpenModal(false);
                  }
                }}
              />
            </div>

            {monitoringType == "HEALTH" && (
              <Box
                sx={{
                  minWidth: 120,
                  marginTop: 1.5,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <FormControl fullWidth>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: "black",
                      opacity: 0.58,
                      fontWeight: "500",
                    }}
                  >
                    {t("ponds.select_shrimp_sick_status")}
                  </Typography>
                  <NativeSelect
                    onChange={(e) => {
                      setShrimpSickStatus(e.target.value);
                    }}
                    value={shrimpSickStatus}
                    inputProps={{
                      name: "select_shrimp_status",
                      id: "uncontrolled-native-shrimp-status",
                    }}
                    sx={{ fontSize: 16 }}
                  >
                    {shrimpStatusList.length > 0 &&
                      shrimpStatusList.map((val) => {
                        return (
                          <option key={val.value} value={val.value}>
                            {val.label}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </FormControl>
              </Box>
            )}

            {monitoringType == "WEIGHT" && (
              <TextField
                id="shrimpWeight"
                label={t("ponds.shrimp_weight")}
                type="number"
                variant="standard"
                required
                onChange={(e) => {
                  setShrimpWeight(e.target.value);
                  setShowError({ ...showError, isShrimpWeight: false });
                }}
                value={shrimpWeight}
                sx={{
                  width: "95%",
                  fontSize: 18,
                  marginTop: 1.5,
                  marginLeft: 2,
                }}
              />
            )}
            {showError.isShrimpWeight && (
              <Alert severity="error">{t("ponds.shrimp_weight_error")}</Alert>
            )}
            {monitoringType == "WEIGHT" && (
              <TextField
                id="shrimpAmount"
                label={t("ponds.amountOfShrimp")}
                type="number"
                variant="standard"
                required
                onChange={(e) => {
                  setShrimpAmount(e.target.value);
                  setShowError({ ...showError, isShrimpAmount: false });
                }}
                value={shrimpAmount}
                sx={{
                  width: "95%",
                  fontSize: 18,
                  marginTop: 1.5,
                  marginLeft: 2,
                }}
              />
            )}
            {showError.isShrimpAmount && (
              <Alert severity="error">{t("ponds.shrimp_amount_error")}</Alert>
            )}
            <Box
              sx={{
                minWidth: 120,
                marginTop: 1.5,
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <FormControl fullWidth>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: "black",
                    opacity: 0.58,
                    fontWeight: "500",
                  }}
                >
                  {t("ponds.select_type")}
                </Typography>
                <NativeSelect
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  value={type}
                  inputProps={{
                    name: "select_type",
                    id: "uncontrolled-native-type",
                  }}
                  sx={{ fontSize: 16 }}
                >
                  {typeList.length > 0 &&
                    typeList.map((val) => {
                      return (
                        <option key={val.value} value={val.value}>
                          {val.label}
                        </option>
                      );
                    })}
                </NativeSelect>
              </FormControl>
            </Box>

            <TextField
              id="comment"
              label={t("ponds.comment")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                setComment(e.target.value);
              }}
              value={comment}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <LoadingButton
                onClick={onClickEdit}
                loading={isDisable}
                loadingPosition="start"
                startIcon={<Save />}
                variant="contained"
                sx={{ backgroundColor: colors.slatery_blue }}
              >
                {t("ponds.save")}
              </LoadingButton>
              {/* </div> */}
            </div>
          </Box>
        </Modal>
        {/* ----- LINK MODAL ----- */}

        {isLoader1 ? (
          <div
            className="link_loafer_block"
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        ) : (
          <Modal
            open={isCreateModal}
            onClose={() => setIsCreateLinkModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={boxStyle}
              className="add-Pond-location add-Pond-location_popup"
            >
              {/* <div style={{ height: "600px", overflow: "auto" }}> */}
              <div
                className="hiding-pond-css"
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: colors.slatery_blue,
                  justifyContent: "space-between",
                  padding: 8,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "600",
                    marginLeft: 1,
                  }}
                >
                  {t("ponds.createALink")}
                </Typography>
                <i
                  className="x icon"
                  style={{ color: "white", fontSize: 22 }}
                  onClick={() => {
                    if (isDisable) {
                    } else {
                      setIsCreateLinkModal(false);
                    }
                  }}
                />
              </div>

              {!recordLink ? (
                <div className="expire_date_block">
                  <p>{t("ponds.expireInDays")}</p>
                  <div className="increment_decrement_main">
                    <p
                      className="increment_decrement_button"
                      onClick={decrement}
                    >
                      -
                    </p>
                    <TextField
                      id="days"
                      // label={t("ponds.expireInDays")}
                      type="number"
                      variant="standard"
                      required
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          if (e.target.value === "") {
                            setDays("");
                          } else {
                            if (
                              Number(e.target.value) > 0 &&
                              Number(e.target.value) <= 90
                            ) {
                              setDays(e.target.value);
                            }
                          }
                        }
                        setShowError({ ...showError, isDays: false });
                      }}
                      value={days}
                    />
                    <p
                      className="increment_decrement_button"
                      onClick={increment}
                    >
                      +
                    </p>
                  </div>
                  <p>{t("ponds.days")}</p>
                </div>
              ) : (
                <div className="recordLink_row">
                  <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                    {t("ponds.recordLink")}
                  </Typography>
                  <a href={recordLink} target="/" sx={{ fontSize: 17 }}>
                    {recordLink}
                  </a>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <LoadingButton
                  onClick={createLink}
                  loading={isDisable}
                  loadingPosition="start"
                  startIcon={<Create />}
                  variant="contained"
                  sx={{ backgroundColor: colors.slatery_blue }}
                >
                  {!recordLink ? t("ponds.creatLink") : t("ponds.edit")}
                </LoadingButton>
                {recordLink && (
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      navigator.clipboard.writeText(recordLink);
                      setCopied(true);
                    }}
                    loading={isDisable}
                    loadingPosition="start"
                    startIcon={isCopied ? <Done /> : <CopyAll />}
                    variant="contained"
                    sx={{ backgroundColor: colors.slatery_blue }}
                  >
                    {isCopied ? t("ponds.copied") : t("ponds.copy")}
                  </Button>
                )}
                {/* </div> */}
              </div>
            </Box>
          </Modal>
        )}

        {/* ----- DELETE MODAL ----- */}
        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={[boxStyle, { width: 400 }]}
            className="logout-css-modal-block"
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                paddingTop: 2,
                textAlign: "center",
                marginBottom: 2,
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              {t("ponds.are_you_sure_you_want_to_delete_this")}
            </Typography>

            <Divider />
            <div className="logout-button-block">
              <Typography
                sx={textStyle}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setIdForUpdate(null);
                    setDeleteModal(false);
                  }
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <div
                style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
              />
              <Typography
                className="delete_bg_color"
                sx={[textStyle, { color: "red" }]}
                onClick={async () => {
                  if (isDisable) {
                  } else {
                    setDeleteModal(false);
                    setIsLoader(true);
                    const item = recordList.filter((ele) => {
                      return ele.id === idForUpdate;
                    });

                    const data = await deleteDataFromDb(
                      idForUpdate,
                      FIRESTORE_POND_RECORD,
                      item.length > 0 ? item[0].recordId : null,
                      cycleId
                    );
                    if (data) {
                      let list = recordList.filter((ele) => {
                        return ele.id !== idForUpdate;
                      });
                      setRecordList(list);
                      // await getRecords();
                      setIsDisable(false);
                      setIdForUpdate(null);
                    }
                    setIsLoader(false);
                    // setDeleteModal(false);
                  }
                }}
              >
                {t("ponds.delete")}
              </Typography>
            </div>
          </Box>
        </Modal>
        <div>
          <Modal
            open={openModalData}
            onClose={() => setOpenModalData(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={boxStyle}
              className="add-Pond-location add-Pond-location_popup"
            >
              <div
                className="hiding-pond-css"
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: colors.slatery_blue,
                  justifyContent: "space-between",
                  padding: 8,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "600",
                    marginLeft: 1,
                  }}
                >
                  {t("ponds.pond_details")}
                </Typography>
                <i
                  className="x icon"
                  style={{ color: "white", fontSize: 22 }}
                  onClick={() => {
                    if (isDisable) {
                    } else {
                      setOpenModalData(false);
                      setIsOpenModal(false);
                    }
                  }}
                />
              </div>
              {modalData && (
                <div className="side_view_block pond_recode_map_auto">
                  <div className="modal_image_row_cm">
                    <div
                      onClick={() => {
                        setIsOpenPic(true);
                        setPicture(modalData.topImage);
                        setIsShowAppBar(false);
                        setOpenModalData(false);
                      }}
                    >
                      <h4>{t("alarm.top_image")}</h4>
                      <img
                        src={
                          modalData
                            ? modalData.topImage
                              ? modalData.topImage
                              : imagePath.noImg
                            : imagePath.noImg
                        }
                      />
                    </div>
                    {monitoringType == "HEALTH" && (
                      <div
                        onClick={() => {
                          setIsOpenPic(true);
                          setPicture(modalData.sideImage);
                          setIsShowAppBar(false);
                          setOpenModalData(false);
                        }}
                      >
                        <h4>{t("alarm.side_image")}</h4>
                        <img
                          src={
                            modalData
                              ? modalData.sideImage
                                ? modalData.sideImage
                                : imagePath.noImg
                              : imagePath.noImg
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="modal_list_alarm_row">
                    <div>
                      <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                        {t("home.pond")}:&nbsp;
                        <span style={{ fontSize: 16, fontWeight: "500" }}>
                          {modalData && modalData.pondName}
                        </span>
                      </Typography>
                    </div>

                    <div>
                      <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                        {t("ponds.cycle")}:&nbsp;
                        <span style={{ fontSize: 16, fontWeight: "500" }}>
                          {modalData && modalData.cycleNo}
                        </span>
                      </Typography>
                    </div>

                    {monitoringType == "HEALTH" && (
                      <div>
                        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                          {t("alarm.shrimp_health")}:&nbsp;
                          <span style={{ fontSize: 16, fontWeight: "500" }}>
                            {modalData
                              ? modalData.shrimpSickStatus === "Yes"
                                ? "Bad"
                                : modalData.shrimpSickStatus === "No"
                                ? "Good"
                                : modalData.shrimpSickStatus
                              : ""}
                          </span>
                        </Typography>
                      </div>
                    )}

                    {monitoringType == "HEALTH" && (
                      <div>
                        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                          {t("alarm.alarm_status")}:&nbsp;
                          <span style={{ fontSize: 16, fontWeight: "500" }}>
                            {modalData && modalData.status}
                          </span>
                        </Typography>
                      </div>
                    )}

                    {monitoringType == "WEIGHT" && (
                      <div>
                        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                          {t("ponds.shrimp_weight")}:&nbsp;
                          <span style={{ fontSize: 16, fontWeight: "500" }}>
                            {modalData && modalData.shrimpWeight}
                          </span>
                        </Typography>
                      </div>
                    )}

                    {monitoringType == "WEIGHT" && (
                      <div>
                        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                          {t("ponds.amountOfShrimp")}:&nbsp;
                          <span style={{ fontSize: 16, fontWeight: "500" }}>
                            {modalData && modalData.shrimpAmount}
                          </span>
                        </Typography>
                      </div>
                    )}

                    <div>
                      <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                        {t("home.description")}:&nbsp;
                        <span style={{ fontSize: 16, fontWeight: "500" }}>
                          {modalData && modalData.comment
                            ? modalData.comment
                            : "-"}
                        </span>
                      </Typography>
                    </div>

                    <div>
                      <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                        {t("home.reported_by")}:&nbsp;{" "}
                        <span style={{ fontSize: 16, fontWeight: "500" }}>
                          {modalData &&
                            (modalData.reportedUser
                              ? modalData.reportedUser
                              : modalData.reportedBy)}
                        </span>
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <PondRecodeMap modalData={modalData} />
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </div>
      </ResponsiveDrawer>
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default PondRecords;
