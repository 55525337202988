import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import { addFarmName, getFarmById, getFarms } from "../../../database/user/pond";
import { useTranslation } from "react-i18next";
import no_alarms_found from "../../../images/no_alarms_found.png";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { deleteFarms, farmWithDeletePond } from "../../../database/user/farm";
import colors from "../../../common/colors";
import { Error, Success } from "../../../common/CustomToast";
import { updateUserById } from "../../../database/user/userManagement";
import { arrayUnion } from "firebase/firestore";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 360 ? 450 : 270,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

function SelectFarm() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [isShowAppBar, setIsShowAppBar] = useState(true);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteAlertModal, setDeleteAlertModal] = useState(false);
  const [farms, setFarms] = useState([]);
  const [newFarms, setNewFarms] = useState([]);
  const [selectFarms, setSelectFarms] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setName("");
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    if (newName.trim() !== "") {
      setError("");
    }
  };

  const handleSubmit = async () => {
    if (name.trim() === "") {
      setError("Please enter your farm name");
      return;
    }
    setButtonLoader(true);
    await addFarmName(name);
    let uid = localStorage.getItem("uid");
    let role = localStorage.getItem("role");
    if(role == "3" || role == "2"){
      let updateData = {
        farmList: arrayUnion(name),
      };
      await updateUserById(uid,updateData);
    }
    setButtonLoader(false);
    setIsModalOpen(false);
    setName("");
    getFarmsData();
  };

  useEffect(() => {
    getFarmsData();
  }, []);

  const getFarmsData = async () => {
    let farmsGet = await getFarms();
    if (!farmsGet || farmsGet.length <= 0) {
      setIsLoader(false);
      return;
    }
    if (farmsGet?.includes("All farms")) {
      const pId = localStorage.getItem('pId')
      const lstFarm = await getFarmById(pId)
      setFarms(["All Ponds", ...lstFarm?.farms]);
      setNewFarms(lstFarm?.farms)
    } else {
      setNewFarms(farmsGet);
      setFarms(["All Ponds", ...farmsGet]);
    }
    setIsLoader(false);
  };

  const deleteFrom = async (name) => {
    setIsLoader(true);
    const newFarm = newFarms.filter((item) => item !== name);
    const isDelete = await deleteFarms(name, newFarm);
    if (isDelete) {
      Success(t("selectFarm.farm_Delete_Successfully"));
    } else {
      setDeleteAlertModal(true);
    }
    getFarmsData();
    setIsLoader(false);
  };

  return (
    <>
      <ResponsiveDrawer
        isShowAppBar={isShowAppBar}
        headText={t("ponds.farms")}
        isHideToolBar={true}
      >
        <div className="select_farm_main">
          <div className="d-flex justify-content-between mb-4">
            <h2>{t("ponds.please_select_farm")}</h2>
            <div className="column base_container pons-button-css-block">
              <button
                className="ui primary button"
                style={{ width: "auto", height: 40 }}
                onClick={openModal}
              >
                {t("selectFarm.addFarm")}
              </button>
            </div>
          </div>
          {isLoader ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div id={`load`} className="loader" />
            </div>
          ) : farms.length > 0 ? (
            <div div className="dashboard_items_row">
              {farms &&
                farms.map((item, index) => {
                  return (
                    <div key={index} className="dashboard_items">
                      {farms[index] !== "All Ponds" && (
                        <div
                          className="delete_icon"
                          onClick={() => {
                            setSelectFarms(farms[index]);
                            setDeleteAlert(true);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 5H4.16667H17.5"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008"
                              stroke="#fff"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M8.3335 9.16675V14.1667"
                              stroke="#fff"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M11.6665 9.16675V14.1667"
                              stroke="#fff"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      )}
                      <div
                        className="dashboard_card"
                        onClick={() => {
                          navigation(`/ponds`, {
                            state: {
                              farm: farms[index],
                            },
                          });
                        }}
                      >
                        <div className="farmer_img">
                          <img
                            src={require("../../../images/farmer.png")}
                            alt=""
                          />
                        </div>
                        <h4 style={{ textAlign: "center" }}>{farms[index]==="All Ponds"? t("ponds.all_ponds") : farms[index]}</h4>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                height: "65vh",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              className="no-alarms-found-css"
            >
              <img src={no_alarms_found} alt="no_alarms_found image" />
              <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                {t("alarm.no_alarms_found")}
              </Typography>
            </div>
          )}
        </div>
      </ResponsiveDrawer>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <div>
            <h5 style={{ marginBottom: "20px" }}>{t("selectFarm.enterYourFarmName")}</h5>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={t("home.name")}
              type="text"
              fullWidth
              value={name}
              onChange={handleNameChange}
              error={error !== ""}
              helperText={error}
              variant="standard"
              style={{ marginTop: -7 }}
            />
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="ui primary button"
                disabled={buttonLoader}
                onClick={handleSubmit}
              >
                {buttonLoader ? t("licenseDetails.loading") : t("ponds.submit")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* ----- DELETE MODAL ----- */}
      <Modal
        open={deleteAlert}
        onClose={() => setDeleteAlert(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("selectFarm.are_you_sure_you_want_to_delete")}
            {`"${selectFarms}" ?`}
          </Typography>

          {/* <Divider /> */}
          <div className="logout-button-block">
            <Typography sx={textStyle} onClick={() => setDeleteAlert(false)}>
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              sx={[textStyle]}
              onClick={() => {
                deleteFrom(selectFarms);
                setDeleteAlert(false);
              }}
              className="delete_bg_color"
            >
              {t("ponds.delete")}
            </Typography>
          </div>
        </Box>
      </Modal>
      {/* ----- DELETE Alert MODAL ----- */}
      <Modal
        open={deleteAlertModal}
        onClose={() => setDeleteAlertModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("selectFarm.you_cannot_delete_this_farm", {
              selectFarms: selectFarms,
            })}
          </Typography>

          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => setDeleteAlertModal(false)}
            >
              {t("device.ok")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SelectFarm;
