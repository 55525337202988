import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";

export const deleteFarms = (farmName, item) => {
  let pId = localStorage.getItem("pId");
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(db, "Ponds"),
        where("pId", "==", pId),
        where("farmName", "==", farmName)
      );
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ ...val.data() });
      });
      if (data.length == 0) {
        const newFarms = {
          farms: item,
        };
        await updateDoc(doc(db, "farms", `${pId}`), newFarms);
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

const getPonds = async (pId, farm = null, farmsAll = null) => {
  return new Promise(async (resolve, reject) => {
    if (farmsAll) {
      const q = query(
        collection(db, "Ponds"),
        where("pId", "==", pId),
        where("farmName", "==", farmsAll)
      );
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      await deleteAlarms(data);
      //   for (const docData of data) {
      //     await updateDoc(doc(db, "Ponds", docData.id), { isDelete: true });
      //   }
      //   const docRef = doc(db, "counters", `${pId}`);
      //   await updateDoc(docRef, {
      //     ponds: increment(-data.length),
      //   });
      return resolve(data);
    }
  });
};

const deleteAlarms = async (data) => {
  return new Promise(async (resolve, reject) => {
    let pId = localStorage.getItem("pId");
    for (const docData of data) {
      const q = query(
        collection(db, "alarms"),
        where("pId", "==", pId),
        where("pondId", "==", docData.id)
      );
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      //   await updateDoc(doc(db, "Ponds", docData.id), { isDelete: true });
    }
    // const docRef = doc(db, "counters", `${pId}`);
    // await updateDoc(docRef, {
    //   ponds: increment(-data.length),
    // });
    return resolve(data);
  });
};

export const farmWithDeletePond = (pondName) => {
  let pId = localStorage.getItem("pId");
  return new Promise(async (resolve, reject) => {
    try {
      await getPonds(pId, null, pondName);
      resolve(null);
    } catch (error) {
      return reject(error);
    }
  });
};
