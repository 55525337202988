import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db, functions } from "../../config/firebase-config";
import { httpsCallable } from "firebase/functions"; 
import { FIREBASE_SET_USERS_VERIFY_ON_SIGNUP_FUNCTION, UPDATE_USER_PASSWORD_FUNCTION } from "../config";

export const getProfileData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let uid = localStorage.getItem('uid');
      if (uid) {
        const docRef = doc(db, `Users`, uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return resolve(docSnap.data());
        } else {
          return resolve(null);
        }
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateProfileById = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let uid = localStorage.getItem("uid");
      if (uid) {
        const docRef = doc(db, "Users", uid);
        await updateDoc(docRef, data);
        return resolve("Successfully updated cycle.");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const confirmPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const changePassFb = httpsCallable(functions, UPDATE_USER_PASSWORD_FUNCTION);
      const result = await changePassFb(data);
      if(result.data.error){
        return resolve(result.data);
      }else{
        return resolve(result.data);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const setUserAsVerified = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usersVerifyOnSignUpFB = httpsCallable(functions, FIREBASE_SET_USERS_VERIFY_ON_SIGNUP_FUNCTION);
      const result = await usersVerifyOnSignUpFB(data);
      if(result.data.error){
        return resolve(result.data);
      }else{
        return resolve(result.data);
      }
    } catch (error) {
      return reject(error);
    }
  });
};
