import { Delete } from "@mui/icons-material";
import {
  Alert,
  Divider,
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import {
  addStockingAmount,
  addStockingAmountReport,
  getCountingRecords,
  getUserData,
} from "../../../database/user/pond";
import {
  getCurrentTimestampInMillis,
  getTimestampWithFormatDate,
} from "../../../common/utils";
import { LoadingButton } from "@mui/lab";
import { DEVICE_MODE_COUNTING, STATUS_ACTIVE } from "../../../database/config";
import moment from "moment";
import SaveIcon from "@mui/icons-material/Save";

function CountingReports() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [cycleId, setCycleId] = useState(
    state.cycleData && state.cycleData.id ? state.cycleData.id : ""
  );
  const [stockingAmount, setStockingAMount] = useState("");
  const [stockingAmountError, setStockingAMountError] = useState("");
  const [isStockingModal, setIsisStockingModalModal] = useState(false);
  const [isStockingLoading, setIsStockingLoading] = useState(false);

  console.log('state.cycleData.id: ', state.cycleData.id);
  const getReport = async () => {
    setLoading(true);
    const reportsLocal = await getCountingRecords(state.cycleData.id);
    setReports(reportsLocal);
    setLoading(false);
  };
  useEffect(() => {
    if (cycleId) {
      getReport();
    } else {
      setLoading(false);
    }
  }, [cycleId]);

  const onManualStocking = async () => {
    if (stockingAmount.trim() === "") {
      setStockingAMountError(t("ponds.biomass_is_required"));
      return;
    }
    setIsStockingLoading(true);
    let uid = localStorage.getItem("uid");
    let userData = await getUserData(uid);
    const pondId = state.pondData.id;
    let countRecordsData = {
      count: Number(stockingAmount),
      mode: DEVICE_MODE_COUNTING,
      time: Number(getCurrentTimestampInMillis()),
      fromPondId: "",
      toPondId: state.pondData.id,
      uploadedOn: moment().toDate(),
      reportedBy: userData?.uid,
      reportedUser: userData?.fullName,
      isFromWeb: true,
    };
    await addStockingAmountReport(countRecordsData, cycleId, pondId);
    await getReport();
    setIsisStockingModalModal(false);
    setStockingAMount("");
    setIsStockingLoading(false);
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("ponds.countingForPond", {
        pondName: state.pondData.pondName,
        batchName: state.cycleData.cycleName,
      })}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation(`/pond_detail`, {
          state: {
            cardData: state.pondData,
            cycleData: state.cycleData,
            cycleId: state?.cycleData?.id,
          },
        });
      }}
    >
      {state?.cycleData?.status == STATUS_ACTIVE && 
        <div className="new_cycle_page_css">
          <button
            className="ui primary button"
            style={{ width: 150, marginLeft: 5 }}
            onClick={() => {
              setIsisStockingModalModal(true);
              setStockingAMount("");
              setStockingAMountError("");
            }}
          >
            {t("ponds.manualStocking")}
          </button>
        </div>}
      {/* ----- TABLE ----- */}
      {reports.length > 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            overflow: "auto",
            marginTop: 18,
          }}
        >
          <table
            className="ui celled table user-manage-tab"
            style={{ marginTop: 0 }}
          >
            <thead>
              <tr>
                <th>{t("ponds.uploadedOn")}</th>
                <th>{t("device.device")}</th>
                <th>{t("device.device_type")}</th>
                <th>{t("home.reported_by")}</th>
                <th>{t("ponds.from")}</th>
                <th>{t("ponds.to")}</th>
                {/* <th>{t("device.to_from")}</th> */}
                <th>{t("device.viewReport")}</th>
                <th>{t("ponds.biomass")}</th>
                {/* <th> </th> */}
              </tr>
            </thead>
            <tbody>
              {reports.map((val, index) => {
                return (
                  <tr
                    // style={{ backgroundColor: val.color }}
                    key={Math.random()}
                  >
                    <td
                      data-label={t("ponds.uploadedOn")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {getTimestampWithFormatDate(
                        val.uploadedOn,
                        "DD-MM-YYYY @ hh:mma"
                      )}
                    </td>
                    <td
                      data-label={t("device.device")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.deviceId ? val.deviceId : t("ponds.manualStocking")}
                    </td>
                    <td
                      data-label={t("device.device_type")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.device ? val.device : t("ponds.manualStocking")}
                    </td>
                    <td
                      data-label={t("device.device_type")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.reportedUser ? val.reportedUser : "-"}
                    </td>
                    <td
                      data-label={t("ponds.from")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {/* {val.deviceId
                        ? state.pondData &&
                          state.pondData.id &&
                          state.pondData.id == val.fromPondId
                          ? "-"
                          : val.fromPondName
                          ? `${t("ponds.from")} (${val.fromPondName})`
                          : "-"
                        : `${t("unknown")}`} */}
                      {val.deviceId === "" || val.deviceId === undefined
                        ? "-"
                        : val.fromPondName}
                    </td>
                    <td
                      data-label={t("ponds.to")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.deviceId === "" || val.deviceId === undefined
                        ? val.toPondName === "" || val.toPondName === undefined
                          ? val.fromPondName || state?.pondData?.pondName
                          : val.toPondName
                        : val.toPondName}
                      {/* {val.deviceId 
                        ? state.pondData &&
                          state.pondData.id &&
                          state.pondData.id == val.fromPondId
                          ? val.toPondName
                            ? `${t("ponds.to")} (${val.toPondName})`
                            : "-"
                          : "-"
                        : (val.toPondName === "" || val.toPondName === undefined) ? val.fromPondName || "-" : val.toPondName} */}
                    </td>

                    {/* <td
                      data-label={t("device.device_type")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.deviceId
                        ? state.pondData &&
                          state.pondData.id &&
                          state.pondData.id == val.fromPondId
                          ? val.toPondName
                            ? `${t("ponds.to")} (${val.toPondName})`
                            : "-̵"
                          : val.fromPondName
                            ? `${t("ponds.from")} (${val.fromPondName})`
                            : "-̵̵"
                        : t("ponds.manualStocking")}
                    </td> */}
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {val.countersData && (
                        <LoadingButton
                          onClick={() => {
                            navigation(`/counting_report`, {
                              state: {
                                recordData: val,
                                pondData: state.pondData,
                                cycleData: state.cycleData,
                              },
                            });
                          }}
                        >
                          {t("device.viewReport")}
                        </LoadingButton>
                      )}
                    </td>
                    <td
                      data-label={t("ponds.totalRecords")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {/* {state.pondData &&
                      state.pondData.id &&
                      state.pondData.id == val.toPondId
                        ? `+${val.count.toLocaleString()}`
                        : val.count < 0
                        ? `-${Math.abs(val.count).toLocaleString()}`
                        : `+${val.count.toLocaleString()}`} */}
                      {val.deviceId ? `${state.pondData.id == val.toPondId ? "+" : "-"} ${val.count.toLocaleString()}`: (val.count.toLocaleString().includes('-')?'':'+')+ val.count.toLocaleString()}
                      {/* {state.pondData && state.pondData.id && state.pondData.id == val.toPondId ?
                       `+${val.count.toLocaleString()}` : val.deviceId ? `-${val.count.toLocaleString()}` : `+${val.count.toLocaleString()}`} */}
                    </td>
                    {/* <td>
                      <div
                        style={style.container}
                        className="farm-svg-css delete_icon_td"
                      >
                        <div className="look_icon_css red_icon_eye">
                          <Delete sx={{ marginLeft: 2 }} onClick={() => {}} />
                        </div>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : !isLoading ? (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
            {state.cycleData && state.cycleData.cycleName
              ? t("ponds.no_counting_report_found", {
                  cycleName: state.cycleData.cycleName,
                })
              : t("ponds.no_report_found_no_cycle")}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )}

      {/* ----- ADD ----- */}
      <Modal
        open={isStockingModal}
        onClose={() => {
          setIsisStockingModalModal(false);
          setStockingAMount("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]}>
          <div
            className="hiding-pond-css"
            style={{
              display: "flex",
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
              backgroundColor: "#1e274c",
              justifyContent: "space-between",
              padding: 8,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "white",
                fontWeight: "600",
                marginLeft: 1,
              }}
            >
              {t("ponds.manualStocking")} {t("login.in")}{" "}
              {state?.pondData && state?.pondData?.pondName}
            </Typography>
            <i
              className="x icon"
              style={{ color: "white", fontSize: 22, cursor: "pointer" }}
              onClick={() => {
                setIsisStockingModalModal(false);
                setStockingAMount("");
              }}
            />
          </div>
          <TextField
            id="stockingAmount"
            label={t("home.biomass")}
            variant="standard"
            required
            type="number"
            onChange={(e) => {
              const value = e.target.value;
              if (!value.includes(".")) {
                setStockingAMount(value);
              }
              setStockingAMountError("");
            }}
            value={stockingAmount}
            sx={{
              width: "95%",
              fontSize: 18,
              marginLeft: 2,
              marginTop: 1,
            }}
            disabled={isStockingLoading}
          />
          {stockingAmountError && (
            <Alert severity="error">{stockingAmountError}</Alert>
          )}

          <div
            style={{ padding: 10, justifyContent: "center" }}
            className="logout-button-block"
          >
            <LoadingButton
              loading={isStockingLoading}
              disabled={isStockingLoading}
              loadingPosition="start"
              startIcon={isStockingLoading ? <SaveIcon /> : ""}
              sx={[
                textStyle,
                {
                  color: "red",
                  fontSize: 14,
                  textTransform: "capitalize",
                  padding: "4px 16px",
                },
              ]}
              onClick={() => {
                onManualStocking();
              }}
            >
              {t("ponds.submit")}
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </ResponsiveDrawer>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 375 ? 400 : 270,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  fontWeight: "600",
};

export default CountingReports;
