import moment from "moment";

const getTimezone = () => {
  const userDeviceTimezone = moment.tz.guess();
  const userTimezone = localStorage.getItem("timezone");
  return userTimezone ? userTimezone : userDeviceTimezone;  
}

export function generateCode(prefix) {
  let code = prefix;
  for (let i = 0; i < 8; i++) {
    code += Math.floor(Math.random() * 10);
  }
  return code;
}

export const nanoTimeToDateTime = (seconds, nanoseconds) => {
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  const formattedDate = moment(date).tz(getTimezone()).toDate();
  return formattedDate;
};

export const nanoTimeToDateTimeString = ({ seconds, nanoseconds },format) => {
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  const formattedDate = moment(date).tz(getTimezone()).format(format);
  return formattedDate;
};

export const isBetweenTowDateToday = (startDate, endDate) => {
  const startOn = new Date(
    startDate.seconds * 1000 + startDate.nanoseconds / 1000000
  );
  const endOn = new Date(
    endDate.seconds * 1000 + endDate.nanoseconds / 1000000
  );

  const formattedDate = moment().isBetween(moment(startOn), moment(endOn));
  return formattedDate;
};

/**
 * Returns the start of a specified format timestamp in the provided timezone.
 * If no timestamp is provided, returns the start of the current timestamp in the given timezone.
 * @param {number} timestamp - The timestamp to be formatted.
 * @param {string} format - The format of the timestamp (e.g., 'day', 'month', 'year').
 * @returns {number} - The start of the formatted timestamp in milliseconds.
 */
export const getStartOfGivenDayTimestamp = async (timestamp) => {
  return moment(timestamp).startOf("day").valueOf();
};

export const getStartOfTimestamp = async (format) => {
  return moment().startOf(format).valueOf();
};

/**
 * Returns the start of the day timestamp for a specified number of days ago in Central European Time (CET).
 * @param {number} count - The number of days ago.
 * @returns {number} - The start of the day timestamp in CET in milliseconds.
 */
export const getDaysAgoStartOfDayTimestamp = (count) => {
  return moment().subtract(count, "days").startOf("day").valueOf();
};

/**
 * Returns a timestamp formatted according to the provided format in the specified timezone.
 * @param {number} item - The timestamp to be formatted.
 * @param {string} format - The desired format of the timestamp.
 * @returns {string} - The formatted timestamp.
 */
export const getTimestampWithFormatDate = (timestamp, format) => {
  if(timestamp.seconds){
    return nanoTimeToDateTimeString(timestamp,format)
  }  
  return moment(Number(timestamp)).tz(getTimezone()).format(format);
};

/**
 * Returns a date formatted with milliseconds according to the provided format in the specified timezone.
 * @param {number} timestamp - The timestamp to be formatted.
 * @param {string} format - The desired format of the date.
 * @returns {string} - The formatted date with milliseconds.
 */
export const getDateWithMillisecondFormat = (timestamp, format) => {
  return moment(Number(timestamp) * 1000)
    .tz(getTimezone())
    .format(format);
};

/**s
 * Returns the current timestamp in milliseconds.
 * @returns {string} - The current timestamp in milliseconds.
 */
export const getCurrentTimestampInMillis = () => {
  return moment().format("x");
};

/**
 * Returns today's date with the provided created timestamp in the specified timezone.
 * If no timestamp is provided, returns today's date in the given timezone.
 * @param {number} timestamp - The created timestamp (optional).
 * @returns {object} - The date object with the provided or current timestamp in the specified timezone.
 */
export const getCalculateDaysDifference = (createdDate, endDate='') => {
  const todayDate = endDate? moment(endDate) : moment();
  const parsedCreatedDate = moment(createdDate);
  const days = todayDate.diff(parsedCreatedDate, "days");
  return days;
};

// Common function to format Unix timestamp to format
export const getFormatDateFromUnixTimestamp = (seconds, format) => {
  return moment.unix(seconds).format(format);
};

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}

export const isSingleDimensionWithoutObjects = (array) => {
  // Check if the input is an array
  if (!Array.isArray(array)) {
      return false;
  }

  // Check if all elements are primitive (string, number, boolean, null, undefined, symbol, bigint)
  // and ensure none of the elements are objects or arrays
  for (let element of array) {
      if (typeof element === 'object' && element !== null) {
          return false; // Returns false if any element is an object or another array
      }
  }

  return true; // Returns true if all elements are primitive
};

export const sumAllArray = (...arrays) => {
  // Find the length of the longest array
  const maxLength = Math.max(...arrays.map(arr => arr.length));

  // Initialize the result array with zeros
  const result = Array(maxLength).fill(0);

  // Sum the corresponding positions of all arrays
  for (let i = 0; i < maxLength; i++) {
      for (let array of arrays) {
          result[i] += array[i] || 0; // Add the current element or 0 if it doesn't exist
      }
  }

  return result
};


