import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  updateDoc,
  setDoc,
  where,
  addDoc,
  deleteDoc,
  deleteField,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { FIRESTORE_DEVICE_POOL, FIRESTORE_LICENSES, FIRESTORE_PLANS } from "../config";
import { SLA } from "../../common/configs";
import { getCurrentTimestampInMillis } from "../../common/utils";

//item wil contain the data like below
/*licenses
  -id
  -pId (main admin id)
  -licenseName(visible to user, random alphanumeric)
  -name
  -type(SLA,Device,Cloud)
  -duration(it will be type, 3M,12M etc)
  -deviceType
  -consumedByUserName
  -paymentStatus (paid, not paid)
  -paymentDate
  -deviceId (which device has this license, no deviceId means the license is not applied to any device yet)
  -startedOn (server timestamp)
  -endedOn (server timestamp)
  -isRecurring
  -price
  */

export const addLicenseToDB = (item) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addID = await addDoc(collection(db, FIRESTORE_LICENSES), item);
      if (item.deviceId) {
        let objDevicePool = {
          pId: item.pId,
        };
        if (item.type === SLA) {
          objDevicePool.slaLicId = addID.id;
        } else {
          objDevicePool.deviceLicId = addID.id;
        }
        const LicKey = item.type === SLA ? "slaLicId" : "deviceLicId";

        let data =  {
          [LicKey]: addID.id
        }
        if(item.isActivateDevice){
          data = {
            ...data,
            deviceName: item.deviceId,
            createdTimestamp: getCurrentTimestampInMillis(),
            pId: item.pId,
          }
        }
        const updateDevicePool = await updateDevicesPool(item.deviceId,data);
      }
      return resolve(addID);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateLicenseToDB = (item) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateID = await updateDoc(
        doc(db, FIRESTORE_LICENSES, item.id),
        item
      );
      if(item.type === SLA){
        const LicKey = item.type === SLA ? "slaLicId" : "deviceLicId";
        const updateDevicePool = await updateDevicesPool(item.deviceId, {[LicKey]: item.id,});
      }
      return resolve(updateID);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLicensesById = async (id) => {
  // let pId = localStorage.getItem("pId");
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, FIRESTORE_LICENSES, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return resolve(docSnap.data());
    } else {
      return resolve(null);
    }
  });
};

export const getLicenses = async (pId) => {
  // let pId = localStorage.getItem("pId");
  return new Promise(async (resolve, reject) => {
    const q = query(
      collection(db, FIRESTORE_LICENSES),
      where("pId", "==", pId)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((val) => {
      data.push({ id: val.id, ...val.data() });
    });
    return resolve(data);
  });
};

export const getPlans = async () => {
  return new Promise(async (resolve, reject) => {
    const q = query(
      collection(db, FIRESTORE_PLANS)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((val) => {
      data.push({ id: val.id, ...val.data() });
    });
    return resolve(data);
  });
};

export const updateDevicesPool = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, FIRESTORE_DEVICE_POOL, id);
      await updateDoc(docRef, data);
      return resolve("Successfully updated device pool.");
    } catch (error) {
      return reject(error);
    }
  });
};

// --------------------------- DELETE ---------------------------
export const deleteLicenses = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (data.deviceId) {
        const licenseKey = data.type === SLA ? "slaLicId" : "deviceLicId";
        const deviceDocRef = doc(
          db,
          `${FIRESTORE_DEVICE_POOL}/${data.deviceId}`
        );
        await updateDoc(deviceDocRef, { [licenseKey]: deleteField() });
      }
      await deleteDoc(doc(db, `${FIRESTORE_LICENSES}`, data.id));
      return resolve("Deleted Successfully");
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateLicenseWithDevicePool = (item, licenseKey) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateID = await updateDoc(
        doc(db, FIRESTORE_LICENSES, item.id),
        item
      );
      const updateDevicePool = await updateDevicesPool(item.deviceId, {
        [licenseKey]: item.id,
      });
      return resolve(updateID);
    } catch (error) {
      return reject(error);
    }
  });
};
