import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

// export const firebaseConfig = {
//   apiKey: "AIzaSyCpwSLt7ZeTRiZllZYlJuWRmjXuu5BorEY",
//   authDomain: "sincere-aqua-dev.firebaseapp.com",
//   projectId: "sincere-aqua-dev",
//   storageBucket: "sincere-aqua-dev.appspot.com",
//   messagingSenderId: "427851542786",
//   appId: "1:427851542786:web:de95aea1ddb7b42eef921b",
//   measurementId: "G-1ECY0MDE12"
// };

export const firebaseConfig = {
  apiKey: "AIzaSyB-pUlX19nE9VT-k_btMuQDPNq3CPhPEuM",
  authDomain: "sincere-aquaculture.firebaseapp.com",
  databaseURL: "https://sincere-aquaculture-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sincere-aquaculture",
  storageBucket: "sincere-aquaculture.appspot.com",
  messagingSenderId: "835137540713",
  appId: "1:835137540713:web:f98db3bf262f7eb9f248f8",
  measurementId: "G-S692C55JHD"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig, {
  experimentalForceLongPolling: true,
  useFetchStreams: false,
});
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
