import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  documentId,
  FieldPath,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  // onSnapshot,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import moment from "moment-timezone";
import { db, storage } from "../../config/firebase-config";
import {
  CYCLE_ID,
  FIRESTORE_BATCH,
  FIRESTORE_COUNTING_RECORDS,
  FIRESTORE_COUNTING_REPORTS,
  FIRESTORE_DEVICE_POOL,
  FIRESTORE_MEASUREMENT_REPORTS,
  FIRESTORE_POND_CYCLE,
  FROM_POND_ID,
  POND_ID,
  POND_LIST,
  FIRESTORE_POND_RECORD,
  TO_POND_ID,
  FIRESTORE_USERS,
  RECORD_DATE,
  TO_CYCLE_ID,
  FROM_CYCLE_ID,
  NO_DATA,
  FIRESTORE_TRANSFER_HISTORY,
  FIRESTORE_PONDS,
  CURRENT_CYCLE_ID,
  FIRESTORE_ALARMS,
  FIRESTORE_COUNTERS,
  HEALTH_CONDITION,
  FIRESTORE_COUNTING_RECORDS_20K,
} from "../config";
import {
  getCurrentTimestampInMillis,
  getTimestampWithFormatDate,
  getDaysAgoStartOfDayTimestamp,
  getStartOfGivenDayTimestamp,
} from "../../common/utils";
import { getLicenses } from "./licensing";

function generateRandomId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const AttachImageInCounter = (img) => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      const id = generateRandomId(4);
      let item = null;
      let url = null;
      if (img) {
        const storageRef = ref(storage, `pond/${pId}-${id}`);
        await uploadBytes(storageRef, img);
        url = await getDownloadURL(ref(storage, `pond/${pId}-${id}`));
      }
      if (url) {
        item = { blueprint: url, blueprintName: `${pId}-${id}` };
      }
      if (item) {
        await updateDoc(doc(db, "counters", `${pId}`), item);
      }
      return resolve(item);
    } catch (error) {
      return reject(error);
    }
  });
};
export const addFarmName = (name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      const docFarmRef = doc(db, "farms", `${pId}`);
      await setDoc(
        docFarmRef,
        {
          farms: arrayUnion(name),
        },
        { merge: true }
      );
      return resolve(name);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addPond = (data, img) => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      const id = generateRandomId(4);
      let item = data;
      let url = null;
      if (img) {
        const storageRef = ref(storage, `pond/${pId}-${id}`);
        await uploadBytes(storageRef, img);
        url = await getDownloadURL(ref(storage, `pond/${pId}-${id}`));
      }
      if (url) {
        item = { ...data, image: url, imgName: `${pId}-${id}` };
      }
      const addID = await addDoc(collection(db, "Ponds"), item);
      // const docRef = doc(db, "counters", "appCounters");
      const docRef = doc(db, "counters", `${pId}`);
      await updateDoc(docRef, {
        ponds: increment(1),
      });
      // const docFarmRef = doc(db, "farms", `${pId}`);
      // await setDoc(
      //   docFarmRef,
      //   {
      //     farms: arrayUnion(data.farmName),
      //   },
      //   { merge: true }
      // );
      // if (data && data.isNursery) {
      //   let batchData = {
      //     batchName: `${item.pondName}_0001`,
      //     timeStamp: Number(moment().tz("CET").format("x")),
      //     pondName: item.pondName,
      //     pondId: addID.id,
      //     pId: pId,
      //     stockingAmount: 0,
      //   };
      //   const generatedBatchID = await addBatch(batchData);
      //   const currentYear = Number(moment().tz("CET").format("YYYY"));
      //   let dataCycle = {
      //     cycleNo: 1,
      //     timeStamp: Number(moment().tz("CET").format("x")),
      //     startDate: Number(moment().tz("CET").format("x")),
      //     status: "Active",
      //     pondName: item.pondName,
      //     pondId: addID.id,
      //     pId: pId,
      //     cycleName: `${currentYear} Cycle 1`,
      //   };
      //   dataCycle = {
      //     ...dataCycle,
      //     cycleName: batchData.batchName,
      //     batchId: `${generatedBatchID}`,
      //     stockingAmount: 0,
      //   };
      //   const docId = await addDoc(collection(db, "pondCycle"), dataCycle);
      //   const docRefPond = doc(db, "Ponds", addID.id);
      //   await updateDoc(docRefPond, {
      //     batchId: `${generatedBatchID}`,
      //     stockingAmount: 0,
      //     cycleCount: 1,
      //     yearCycleCount: 1,
      //     currentCycleId: docId.id,
      //   });
      // }
      return resolve(addID.id);
    } catch (error) {
      console.log("error", error);
      return reject(error);
    }
  });
};

export const updatePond = (idImg, data, img) => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      let item = data;
      let url = null;
      if (img) {
        const storageRef = ref(storage, `pond/${idImg.img}`);
        await uploadBytes(storageRef, img);
        url = await getDownloadURL(ref(storage, `pond/${idImg.img}`));
      }
      if (url) {
        item = { ...data, image: url, imgName: `${idImg.img}` };
      }
      await updateDoc(doc(db, "Ponds", idImg.id), item);
      const docFarmRef = doc(db, "farms", `${pId}`);
      await setDoc(
        docFarmRef,
        {
          farms: arrayUnion(data.farmName),
        },
        { merge: true }
      );

      return resolve(item);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPond = (pId, farm = null, farmsAll = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (farm && farm != "All") {
        const q = query(
          collection(db, "Ponds"),
          where("pId", "==", pId),
          where("farmName", "==", farm)
        );
        const querySnapshot = await getDocs(q);
        let data = [];
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        // if (farmsAll && farmsAll.length > 0) {
        //   const q = query(collection(db, "Ponds"), where("pId", "==", pId), where("farmName", "in", farmsAll));
        //   const querySnapshot = await getDocs(q);
        //   let data = [];
        //   querySnapshot.forEach((val) => {
        //     data.push({ id: val.id, ...val.data() });
        //   });
        //   return resolve(data);
        // } else {
        const q = query(collection(db, "Ponds"), where("pId", "==", pId));
        const querySnapshot = await getDocs(q);
        let data = [];
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
        // }
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getFarms = () => {
  let pId = localStorage.getItem("pId");
  let mainUserId = localStorage.getItem("mainUserId");
  let role = localStorage.getItem("role");
  let uid = localStorage.getItem("uid");
  return new Promise(async (resolve, reject) => {
    try {
      if (pId === mainUserId && role === "2") {
        const citiesRef = collection(db, "Users");
        const q = query(citiesRef, where("uid", "==", uid));
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data[0].farmList);
      }
      const docRef = doc(db, "farms", `${pId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return resolve(docSnap.data().farms);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getFarmById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "farms", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPondById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "Ponds", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const addPondImageById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "Ponds", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

// --------------------------- CYCLE ---------------------------
export const getPondCycle = (id, isNonActive = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        let data = [];
        if (!isNonActive) {
          const q = query(
            collection(db, "pondCycle"),
            where("pondId", "==", id)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((val) => {
            data.push({ id: val.id, ...val.data() });
          });
          const queryPondList = query(
            collection(db, FIRESTORE_POND_CYCLE),
            where(POND_LIST, "array-contains", id)
          );
          const queryPondLisSnapshot = await getDocs(queryPondList);
          queryPondLisSnapshot.forEach((val) => {
            const pondCycleFound = data.some((obj) => obj.id === val.id);
            if (!pondCycleFound || pondCycleFound.length <= 0) {
              data.push({ id: val.id, ...val.data() });
            }
          });
        } else {
          const q = query(
            collection(db, "pondCycle"),
            where("pondId", "==", id),
            where("status", "==", "Inactive")
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((val) => {
            data.push({ id: val.id, ...val.data() });
          });
        }
        // realTime read
        // onSnapshot(q, (snapshot) => {
        //   snapshot.docs.forEach((element) => {
        //     if (element.exists()) {
        //       data.push({ id: element.id, ...element.data() });
        //     }
        //   });
        // return resolve(data);
        // });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getActivePondCycle = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const q = query(
          collection(db, "pondCycle"),
          where("pondId", "==", id),
          where("status", "==", "Active")
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getActivePondCycleInActive = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const q = query(
          collection(db, "pondCycle"),
          where("pondId", "==", id),
          where("status", "!=", "Active")
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getActivePondCycleByCycleId = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, "pondCycle", id);
        const dataSnap = await getDoc(docRef);
        let data = [];
        if (dataSnap) {
          data.push({ id: id, ...dataSnap.data() });
        }
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

// export const getShrimpWeightBefore7DayCycle = (id) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       if (id) {
//         const docRef = doc(
//           db,
//           `pondCycle/${id}/shrimpWeight`,
//           moment().tz('CET').subtract(7, "d").startOf("day").format("x")
//         );
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           return resolve(docSnap.data());
//         } else {
//           return resolve(null);
//         }
//       } else {
//         return resolve(null);
//       }
//     } catch (error) {
//       return reject(error);
//     }
//   });
// };

// export const getShrimpWeightTodayCycle = (id) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       if (id) {
//         const docRef = doc(
//           db,
//           `pondCycle/${id}/shrimpWeight`,
//           moment().tz('CET').startOf("day").format("x")
//         );
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {

//           return resolve(docSnap.data());
//         } else {
//           return resolve(null);
//         }
//       } else {
//         return resolve(null);
//       }
//     } catch (error) {
//       return reject(error);
//     }
//   });
// };

export const getShrimpWeightAllCycle = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const querySnapshot = await getDocs(
          collection(db, `pondCycle/${id}/shrimpWeight`)
        );
        let data = [];
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const updatePondCycleNameById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "pondCycle", id);
      await updateDoc(docRef, data);
      return resolve("Successfully updated cycle.");
    } catch (error) {
      return reject(error);
    }
  });
};

export const addPondCycle = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docId = await addDoc(collection(db, "pondCycle"), data);
      const docRef = doc(db, "Ponds", data.pondId);
      const amount = Number(data.stockingAmount);

      await updateDoc(docRef, {
        stockingAmount: increment(amount),
        currentCycleId: docId.id,
        healthCondition: NO_DATA,
      });
      return resolve(docId.id);
    } catch (error) {
      return reject(error);
    }
  });
};

export const fetchTotalRecordsOfAssociatedCycles = (pondCycleId) => {
  return new Promise(async (resolve, reject) => {
    if (pondCycleId) {
      const q = query(
        collection(db, FIRESTORE_TRANSFER_HISTORY),
        where(TO_CYCLE_ID, "==", pondCycleId)
      );
      let data = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      const fromCycleIds = [...new Set(data.map((item) => item.fromCycleId))];
      fromCycleIds.push(pondCycleId);
      if (fromCycleIds && fromCycleIds.length > 0) {
        const qRecords = query(
          collection(db, FIRESTORE_POND_CYCLE),
          where(documentId(), "in", fromCycleIds)
        );
        const querySnapshotRecords = await getDocs(qRecords);
        let dataRecords = [];
        querySnapshotRecords.forEach((val) => {
          dataRecords.push({ id: val.id, ...val.data() });
        });
        return resolve(dataRecords);
      } else {
        return resolve([]);
      }
    } else {
      return resolve([]);
    }
  });
};

export const addStockingAmount = (countRecordsData, cycleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const subCollectionRef = collection(db, FIRESTORE_COUNTING_RECORDS);
      await setDoc(doc(subCollectionRef), {
        ...countRecordsData,
        [FROM_CYCLE_ID]: cycleId,
        [TO_CYCLE_ID]: "",
      });
      return resolve(true);
    } catch (error) {
      console.log(error);
      return reject(error);
    }
  });
};

export const addStockingAmountReport = async (countRecordsData, cycleId, pondId) => {
  try {
    const subCollectionRef = collection(db, FIRESTORE_COUNTING_RECORDS);
    const docRef = doc(db, FIRESTORE_PONDS, pondId);
    
    // Add the count record
    await setDoc(doc(subCollectionRef), {
      ...countRecordsData,
      [FROM_CYCLE_ID]: "",
      [TO_CYCLE_ID]: cycleId,
    });

    // Update the main pond document
    await updateDoc(docRef, {
      stockingAmount: increment(countRecordsData.count),
    });

    const docRefCycle = doc(db, FIRESTORE_POND_CYCLE, cycleId);
     // Update the main cycle document
     await updateDoc(docRefCycle, {
      stockingAmount: increment(countRecordsData.count),
    });
    
    return true;
  } catch (error) {
    console.log(error);
    throw error; // Reject the promise with the error
  }
};


// --------------------------- RECORD ---------------------------

export const getLimitedPondRecord = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const q = query(
          collection(db, "pondRecord"),
          where("cycleId", "==", id),
          limit(5)
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getExceededOrNot = (cycleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = [];
      const q = query(
        collection(db, `pondCycle/${cycleId}/waterParamRecord`),
        orderBy("timestamp", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });
      if (data && data.length > 0) {
        if (data[0].isExceeded) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      } else {
        return resolve(false);
      }
    } catch (error) {
      return reject(false);
    }
  });
};

export const getPondRecordById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "pondRecord", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getWeeklyAverage = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        let data = [];
        const count = 7;
        const lastWeekDate = getDaysAgoStartOfDayTimestamp(count);
        const q = query(
          collection(db, `pondCycle/${id}/shrimpWeight`),
          where(documentId(), ">=", `${lastWeekDate}`),
          limit(7)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot && querySnapshot.size > 0) {
          querySnapshot.forEach((val) => {
            data.push({ id: val.id, ...val.data() });
          });
          let allDayAverageWeight = 0;
          if (data && data.length > 0) {
            for (const item of data) {
              if (item.noOfRecord > 0) {
                allDayAverageWeight =
                  allDayAverageWeight + item.weight / item.noOfRecord;
              }
            }
          }
          const totalAvg = allDayAverageWeight / data.length;
          return resolve(Number(totalAvg).toFixed(2));
        } else {
          return resolve(0);
        }
      } else {
        return resolve(0);
      }
    } catch (error) {
      return reject(0);
    }
  });
};

export const getLastDaysData = (id, count) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        let data = [];
        const lastWeekDate = getDaysAgoStartOfDayTimestamp(count);
        const q = query(
          collection(db, `pondCycle/${id}/waterParamRecord`),
          where("timestamp", ">=", lastWeekDate),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot && querySnapshot.size > 0) {
          querySnapshot.forEach((val) => {
            data.push({ id: val.id, ...val.data() });
          });
          var _ = require("lodash");
          let groupedResults = _.groupBy(data, (result) =>
            getStartOfGivenDayTimestamp(result["timestamp"])
          );
          let keys = Object.keys(groupedResults);
          let dates = [],
            ph = [],
            ammonia = [],
            dissolvedOxygen = [],
            nitrite = [],
            salinity = [],
            temperature = [],
            turbidity = [];
          for (const item of keys) {
            dates.push(getTimestampWithFormatDate(item, "DD MMM"));
            if (groupedResults[item] && groupedResults[item].length > 0) {
              let totalPh = 0;
              let totalAmmonia = 0;
              let totalDissolvedOxygen = 0;
              let totalNitrite = 0;
              let totalSalinity = 0;
              let totalTemperature = 0;
              let totalTurbidity = 0;
              for (const itemGroup of groupedResults[item]) {
                totalPh = totalPh + Number(itemGroup.ph);
                totalAmmonia = totalAmmonia + Number(itemGroup.ammonia);
                totalDissolvedOxygen =
                  totalDissolvedOxygen + Number(itemGroup.dissolvedOxygen);
                totalNitrite = totalNitrite + Number(itemGroup.nitrite);
                totalSalinity = totalSalinity + Number(itemGroup.salinity);
                totalTemperature =
                  totalTemperature + Number(itemGroup.temperature);
                totalTurbidity = totalTurbidity + Number(itemGroup.turbidity);
              }
              let avgPh = Number(totalPh) / groupedResults[item].length;
              let avgAmmonia =
                Number(totalAmmonia) / groupedResults[item].length;
              let avgDissolvedOxygen =
                Number(totalDissolvedOxygen) / groupedResults[item].length;
              let avgNitrite =
                Number(totalNitrite) / groupedResults[item].length;
              let avgSalinity =
                Number(totalSalinity) / groupedResults[item].length;
              let avgTemperature =
                Number(totalTemperature) / groupedResults[item].length;
              let avgTurbidity =
                Number(totalTurbidity) / groupedResults[item].length;
              ph.push(avgPh);
              ammonia.push(avgAmmonia);
              dissolvedOxygen.push(avgDissolvedOxygen);
              nitrite.push(avgNitrite);
              salinity.push(avgSalinity);
              temperature.push(avgTemperature);
              turbidity.push(avgTurbidity);
            }
          }
          let finalData = {
            dates: dates,
            ph: ph,
            ammonia: ammonia,
            dissolvedOxygen: dissolvedOxygen,
            nitrite: nitrite,
            salinity: salinity,
            temperature: temperature,
            turbidity: turbidity,
          };
          return resolve(finalData);
        } else {
          return resolve(null);
        }
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

export const getPondRecord = (
  id,
  key,
  type = "HEALTH",
  queryLimit,
  recordKey = CYCLE_ID,
  isNursery = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        if (!isNursery) {
          const q = query(
            collection(db, FIRESTORE_TRANSFER_HISTORY),
            where(TO_CYCLE_ID, "==", id)
          );
          let data = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((val) => {
            data.push({ id: val.id, ...val.data() });
          });
          const fromCycleIds = [
            ...new Set(data.map((item) => item.fromCycleId)),
          ];
          fromCycleIds.push(id);
          if (fromCycleIds && fromCycleIds.length > 0) {
            if (key) {
              const q = query(
                collection(db, FIRESTORE_POND_RECORD),
                where(recordKey, "in", fromCycleIds),
                orderBy(RECORD_DATE, "desc"),
                startAfter(key),
                limit(queryLimit)
              );
              let data = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((val) => {
                data.push({ id: val.id, ...val.data() });
              });
              return resolve(data);
            } else {
              if (type == "ALL") {
                const q = query(
                  collection(db, FIRESTORE_POND_RECORD),
                  where(recordKey, "in", fromCycleIds),
                  orderBy(RECORD_DATE, "desc"),
                  limit(queryLimit)
                );
                let data = [];
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((val) => {
                  data.push({ id: val.id, ...val.data() });
                });
                return resolve(data);
              } else {
                const q = query(
                  collection(db, FIRESTORE_POND_RECORD),
                  where(recordKey, "in", fromCycleIds),
                  where(
                    "monitoringType",
                    "==",
                    `${
                      type == "HEALTH"
                        ? "monitoring_health"
                        : "monitoring_weight"
                    }`
                  ),
                  orderBy(RECORD_DATE, "desc"),
                  limit(queryLimit)
                );
                let data = [];
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((val) => {
                  data.push({ id: val.id, ...val.data() });
                });
                return resolve(data);
              }
            }
          } else {
            return resolve([]);
          }
        } else {
          if (key) {
            const q = query(
              collection(db, FIRESTORE_POND_RECORD),
              where(recordKey, "==", id),
              orderBy(RECORD_DATE, "desc"),
              startAfter(key),
              limit(queryLimit)
            );
            let data = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((val) => {
              data.push({ id: val.id, ...val.data() });
            });
            return resolve(data);
          } else {
            if (type == "ALL") {
              const q = query(
                collection(db, FIRESTORE_POND_RECORD),
                where(recordKey, "==", id),
                orderBy(RECORD_DATE, "desc"),
                limit(queryLimit)
              );
              let data = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((val) => {
                data.push({ id: val.id, ...val.data() });
              });
              return resolve(data);
            } else {
              const q = query(
                collection(db, FIRESTORE_POND_RECORD),
                where(recordKey, "==", id),
                where(
                  "monitoringType",
                  "==",
                  `${
                    type == "HEALTH" ? "monitoring_health" : "monitoring_weight"
                  }`
                ),
                orderBy(RECORD_DATE, "desc"),
                limit(queryLimit)
              );
              let data = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((val) => {
                data.push({ id: val.id, ...val.data() });
              });
              return resolve(data);
            }
          }
        }
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateRecord = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, "alarms", id);
        await updateDoc(docRef, data);
        return resolve(data);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const createLinkRecords = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (data) {
        const addID = await addDoc(collection(db, "linkSharingRecords"), data);
        return resolve(addID.id);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

export const updateLinkRecords = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, "linkSharingRecords", `${id}`);
        await updateDoc(docRef, data);
        return resolve(docRef.id);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

export const getLinkRecords = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const q = query(
          collection(db, "linkSharingRecords"),
          where("linkRecordId", "==", id),
          limit(1)
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data[0]);
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(null);
    }
  });
};

//FETCH MEASUREMENT REPORTS BY batchId
export const getMeasurementReports = (id, pondId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const measurementRef = collection(
          db,
          `${FIRESTORE_BATCH}/${id}/${FIRESTORE_MEASUREMENT_REPORTS}`
        );
        let data = [];
        if (pondId) {
          const q = query(measurementRef, where(POND_ID, "==", pondId));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((val) => {
            data.push({ id: val.id, ...val.data() });
          });
        }
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

//FETCH COUNTING RECORDS BY cycleId
export const getCountingRecords = (cycleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const countingRefSC4K = collection(db, `${FIRESTORE_COUNTING_RECORDS}`);
      let data = [];
      //GET FROM toPond
      if (cycleId) {
        const q = query(countingRefSC4K, where(TO_CYCLE_ID, "==", cycleId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, key: val.id, ...val.data() });
        });
      }

      //GET FROM fromPond
      if (cycleId) {
        const q = query(countingRefSC4K, where(FROM_CYCLE_ID, "==", cycleId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, key: val.id, ...val.data() });
        });
      }

      const countingRefSC20K = collection(
        db,
        `${FIRESTORE_COUNTING_RECORDS_20K}`
      );
      //GET FROM toPond
      if (cycleId) {
        const q = query(countingRefSC20K, where(TO_CYCLE_ID, "==", cycleId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, key: val.id, ...val.data() });
        });
      }

      //GET FROM fromPond
      if (cycleId) {
        const q = query(countingRefSC20K, where(FROM_CYCLE_ID, "==", cycleId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, key: val.id, ...val.data() });
        });
      }

      if (data) {
        const sortedRecords = data.sort((a, b) => {
          // Parse uploadedOn as a number for comparison
          const uploadedOnA = typeof a.uploadedOn === 'object' ? (a.uploadedOn.seconds * 1000) : Number(a.uploadedOn);
          const uploadedOnB = typeof b.uploadedOn === 'object' ? (b.uploadedOn.seconds * 1000) : Number(b.uploadedOn);
          return uploadedOnB - uploadedOnA; // Sort in descending order
        });
        // const sortedRecords = data.sort((a, b) => {
        //   if (a.uploadedOn && b.uploadedOn) {
        //     return b.uploadedOn - a.uploadedOn; // Sort by uploadedOn if both are present
        //   } else if (!a.uploadedOn && !b.uploadedOn) {
        //     return b.time - a.time; // If uploadedOn is not present, sort by time
        //   } else {
        //     // If only one of uploadedOn is not present, prioritize the one with uploadedOn
        //     return a.uploadedOn ? -1 : 1;
        //   }
        // });
        return resolve(sortedRecords);
      } else {
        return resolve(data);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

//FETCH COUNTING REPORTS BY batchId
export const getCountingReports = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const querySnapshot = await getDocs(
          collection(
            db,
            `${FIRESTORE_BATCH}/${id}/${FIRESTORE_COUNTING_REPORTS}`
          )
        );
        let data = [];
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

//get Report By Report Id
// export async function getReportByReportId(reportId, batchId) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       if (!reportId) {
//         return null;
//       }
//       if (batchId) {
//         const citiesRef = collection(
//           db,
//           `${FIRESTORE_BATCH}/${batchId}/sizeRecords`
//         );
//         const q = query(
//           citiesRef,
//           where("reportId", "==", reportId),
//           where("isUploaded", "==", false)
//         );
//         const querySnapshot = await getDocs(q);
//         let data = [];
//         querySnapshot.forEach((val) => {
//           data.push({ id: val.id, ...val.data() });
//         });
//         let array = [];
//         data.map((record) => {
//           const data = {
//             id: record.id,
//             time: record.time,
//             count: record.count,
//             fromPondId: record.fromPondId,
//             toPondId: record.toPondId,
//             mode: record.mode,
//             deviceId: record.deviceId,
//             device: record.device,
//             reportId: record.reportId,
//             isUploaded: record.isUploaded,
//             batchId: record.batchId,
//           };
//           array.push(data);
//         });
//         return resolve(array);
//       } else {
//         return resolve([]);
//       }
//     } catch (error) {
//       return reject(error);
//     }
//   });
// }

// --------------------------- BIOMASS RECORD ---------------------------
export const getBiomassRecordAllCycle = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const querySnapshot = await getDocs(
          collection(db, `pondCycle/${id}/biomassRecord`)
        );
        let data = [];
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

// --------------------------- WATER PARAMETERS RECORD ---------------------------
export const getWaterParametersAllCycle = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const querySnapshot = await getDocs(
          collection(db, `pondCycle/${id}/waterParamRecord`),
          orderBy("timestamp", "desc")
        );
        let data = [];
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};
export const getUserData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, `Users`, `${id}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return resolve(docSnap.data());
        } else {
          return resolve(null);
        }
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const addBiomassRecord = (id, num, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addRef = await addDoc(
        collection(db, `pondCycle/${id}/biomassRecord`),
        data
      );
      let pId = localStorage.getItem("pId");
      // const docRef = doc(db, "counters", "appCounters");
      const docRef = doc(db, "counters", `${pId}`);
      await updateDoc(docRef, {
        biomass: increment(Number(num)),
      });
      return resolve(addRef.id);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addWaterParamRecord = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addRef = await addDoc(
        collection(db, `pondCycle/${id}/waterParamRecord`),
        data
      );
      let pId = localStorage.getItem("pId");
      // const docRef = doc(db, "counters", "appCounters");
      const docRef = doc(db, "counters", `${pId}`);
      await updateDoc(docRef, {
        waterParam: increment(1),
      });
      const docRefCycle = doc(db, "pondCycle", `${id}`);
      await updateDoc(docRefCycle, {
        waterParam: increment(1),
      });
      return resolve(addRef.id);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteBiomassRecord = (id, data, cycleBiomass) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        await deleteDoc(doc(db, `pondCycle/${id}/biomassRecord`, data.id));
        let pId = localStorage.getItem("pId");
        // const docRef = doc(db, "counters", "appCounters");
        const docRef = doc(db, "counters", `${pId}`);
        const num = Number(data.biomass) >= 0 ? Number(data.biomass) : 0;
        await updatePondCycleById(id, { biomass: cycleBiomass });
        await updateDoc(docRef, {
          biomass: increment(-num),
        });
        return resolve("Deleted Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteWaterRecord = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        await deleteDoc(doc(db, `pondCycle/${id}/waterParamRecord`, data.id));
        let pId = localStorage.getItem("pId");
        // const docRef = doc(db, "counters", "appCounters");
        const docRef = doc(db, "counters", `${pId}`);
        await updateDoc(docRef, {
          waterParam: increment(Number(-1)),
        });
        await updatePondCycleById(id, { waterParam: increment(Number(-1)) });

        return resolve("Deleted Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

// --------------------------- DELETE ---------------------------
export const deletePond = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      if (data.imgName) {
        const storageRef = ref(storage, `pond/${data.imgName}`);
        await deleteObject(storageRef);
      }
      await deleteDoc(doc(db, "Ponds", data.id));
      // const docRef = doc(db, "counters", "appCounters");
      const docRef = doc(db, "counters", `${pId}`);
      await updateDoc(docRef, {
        ponds: increment(-1),
      });
      return resolve("Deleted Successfully");
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteDataFromDb = (id, folder, recordId, cycleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        if (recordId) {
          try {
            const desertRefTop = ref(
              storage,
              `pondRecords/pond_${recordId}_top`
            );
            const desertRefSide = ref(
              storage,
              `pondRecords/pond_${recordId}_side`
            );
            await deleteObject(desertRefTop);
            await deleteObject(desertRefSide);
          } catch (e) {
            console.log("e: ", e);
          }
          await deleteDoc(doc(db, folder, id));
          let pId = localStorage.getItem("pId");
          const docRef = doc(db, FIRESTORE_COUNTERS, `${pId}`);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            if (docSnap.data().records > 0) {
              await updateDoc(docRef, {
                records: increment(-1),
              });
            }
          }
          const documentRef = doc(db, FIRESTORE_ALARMS, id);
          const documentSnapshot = await getDoc(documentRef);
          if (documentSnapshot.exists()) {
            await deleteDoc(doc(db, FIRESTORE_ALARMS, id));
            await updateDoc(docRef, {
              abnormalBiomass: increment(-1),
            });
            console.log("Document exists");
          } else {
            console.log("Document does not exist");
          }
          const docRefCycle = doc(db, FIRESTORE_POND_CYCLE, cycleId);
          await updateDoc(docRefCycle, {
            records: increment(-1),
          });
        }
        return resolve("Deleted Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const addWaterQuality = (data, pondId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addID = await setDoc(
        doc(db, "waterParamThreshold", `${pondId}`),
        data
      );
      return resolve(addID);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getWaterQuality = (pondId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const querySnapshot = await getDoc(
        doc(db, "waterParamThreshold", `${pondId}`)
      );
      return resolve(querySnapshot.data());
    } catch (error) {
      return reject(error);
    }
  });
};

export const addDevice = (data, pondId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addID = await setDoc(
        doc(db, FIRESTORE_DEVICE_POOL, `${pondId}`),
        data
      );
      return resolve(addID);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDevices = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const querySnapshot = await getDocs(
        collection(db, FIRESTORE_DEVICE_POOL)
      );
      let data = [];

      const promises = querySnapshot.docs.map(async (val) => {
        const docRef = doc(db, FIRESTORE_USERS, `${val.data().pId}`);
        const docSnap = await getDoc(docRef);
        return { ...val.data(), name: docSnap.data()?.fullName || "" };
      });

      const results = await Promise.all(promises);
      data = results.filter((result) => !!result); // Filter out undefined results

      return resolve(data);
    } catch (error) {
      console.log("error: ", error);
      return reject(error);
    }
  });
};

export const getDeviceById = (deviceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, FIRESTORE_DEVICE_POOL, deviceId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllParentUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // whereEqualTo("website", null)
      const q = query(collection(db, "Users"));
      const querySnapshot = await getDocs(q);

      const promises = querySnapshot.docs.map(async (val) => {
        const licenseData = await getLicenses(val?.data()?.uid);
        return { ...val.data(), licenseData: licenseData };
      });
      const results = await Promise.all(promises);
      return resolve(results);
    } catch (error) {
      console.log("error: ", error);
      return reject(error);
    }
  });
};
// purvik
export const userOperationWithAllChildById = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "Users", `${id}`);
      await updateDoc(docRef, data);

      const citiesRef = collection(db, "Users");
      const q = query(citiesRef, where("pId", "==", id));
      let lstPromise = [];
      const querySnapshotNew = await getDocs(q);

      querySnapshotNew.forEach((val) => {
        const docRef = doc(db, "Users", `${val.data().uid}`);
        lstPromise.push(updateDoc(docRef, data));
      });
      let lstTmp = await Promise.all(lstPromise);
      resolve(true);
      return true;
    } catch (error) {
      console.log(error);
      return reject(error);
    }
  });
};

export const updatePondCycleById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "pondCycle", id);
      await updateDoc(docRef, data);
      return resolve("Successfully updated cycle.");
    } catch (error) {
      return reject(error);
    }
  });
};

export const removeCurrentCycleFromPond = (pondId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, FIRESTORE_PONDS, pondId);
      await updateDoc(docRef, {
        [CURRENT_CYCLE_ID]: "",
        [HEALTH_CONDITION]: NO_DATA,
      });
      return resolve("Successfully updated pond.");
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteDevice = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await deleteDoc(doc(db, FIRESTORE_DEVICE_POOL, id));
      return resolve(id);
    } catch (error) {
      return reject(error);
    }
  });
};
