import emailjs from '@emailjs/browser';
import axios from 'axios';
// import formData from 'form-data'
import mailgun from 'mailgun.js';
import { Success } from '../common/CustomToast';

// const mg = mailgun(formData);
// const mgClient = mg.client({
//   username: 'api',
//   key: 'f0afa6fab298cab65a993675cba38886-2b755df8-e947660e', // Replace with your Mailgun API key
// });

export const sendEmail = (form) => {
  emailjs.send('aqua', 'template_apr4pob', form, 'MT-6VtckXClNZlyJ9')
    .then((result) => {
      alert('Email sent successfully!');
    }, (error) => {
      console.log(error.text);
      alert('Failed to send email.');
    });
};

export const sendEmailMailgun = async (objMail) => {
  const { fromName, userEmail, toName, message } = objMail
  try {
    let data = new FormData();
    data.append('from', `Excited ${fromName} <mailgun@sandboxafe08b56fe8644fe99ceaed34ac37efc.mailgun.org>`);
    data.append('to', userEmail);
    data.append('subject', ' Verify your emai');
    // data.append('text', `Dear ${toName} 3,\n
    //   Please follow the link to sign up: ${message}\n
    //   Best regards,\n
    //   Sincere Aqua Team`
    // );

    // data.append('html', `<p>Dear <span style="font-weight: bold;">${toName}</span>,</p>
    //   <p>Please follow the link to sign up: <a href="${message}" target="blank" style="color: #1a73e8;">Sign Up Here</a></p>
    //   <p>Best regards,</p>
    //   <p>Sincere Aqua Team</p>`)

    data.append('html', `<p>Dear Customer,</p>
<p>Verify this email address for your Sincere Aqua account by clicking the link below</p>
<p><a href=${message}>Approve Request</a></p>
<p>If you are not expecting to receive this email – please disregard this email.</p>
<p>The Sincere Aqua Team</p>
<div style="display: flex; width: 100%; justify-content: flex-end; flex-direction: row;">
    <img src="https://firebasestorage.googleapis.com/v0/b/sincere-aqua-dev.appspot.com/o/main_logo.a96af33f6650ebe4a1c2.png?alt=media&token=ef40b96c-ee33-4849-b5ed-6e08c0847444"
        alt="Sincere Aqua Logo" style="width: 250px;">
</div>`)


    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.mailgun.net/v3/sandboxafe08b56fe8644fe99ceaed34ac37efc.mailgun.org/messages',
      headers: {
        'Authorization': 'Basic YXBpOmYwYWZhNmZhYjI5OGNhYjY1YTk5MzY3NWNiYTM4ODg2LTJiNzU1ZGY4LWU5NDc2NjBl',
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response.status === 200) {
          Success('User invitation mail send successfully')
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return 'response';
  } catch (error) {
    console.error('Error sending email: ', error);
    throw error;
  }
};

