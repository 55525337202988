import { Card, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function DetailCard(props) {
  const {
    heading,
    isPond,
    count,
    color,
    isClickable,
    onClickRecords,
    isRecord,
    onClickCard,
    isUpdate,
    onClickUpdateBiomass,
    card,
    isWaterQuality,
    isWaterThreshold,
    isRecordLoader,
    isPrefix,
    buttonText,
    onClickGoNavigate,
  } = props;
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        padding: 2,
        boxShadow: "1px 3px 3px 4px #e0e0e0",
        height: 180,
      }}
      onClick={onClickCard}
    >
      <div className="card-icons-block">
        <img src={card} alt="Image" />
      </div>
      <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
        {heading}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          gap: 7,
        }}
        className="report-button-css-block"
      >
        <div style={{ display: "flex" }}>
          {isPrefix && (
            <Typography
              sx={{
                fontSize: isPond ? 25 : 20,
                fontWeight: "800",
                color: color,
              }}
            >
              {t("ponds.biomass")}:
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: isPond ? 25 : 20,
              fontWeight: "800",
              color: color,
            }}
          >
            {isRecordLoader ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div id={`load`} className="loader" />
                </div>
              </>
            ) : (
              count?.toLocaleString()
            )}
          </Typography>
        </div>
        {/* {isRecord && (
          <button
            className="ui  button"
            style={{ width: "auto", height: 40 }}
            onClick={() => onClickRecords()}
          >
            {t("ponds.see_records")}
          </button>
        )} */}
        {isClickable && (
          <button
            className="ui  button"
            style={{ width: "auto", height: 40 }}
            onClick={() => onClickUpdateBiomass()}
          >
            {t("home.view_parameters")}
          </button>
          // <Typography
          //   sx={{
          //     fontSize: 18,
          //     fontWeight: "600",
          //     color: color,
          //     marginTop: 0.5,
          //   }}
          // >
          //   {t("ponds.click_here_to_see")}
          // </Typography>
        )}
        {isWaterQuality && (
          <button
            className="ui  button"
            style={{ width: "auto", height: 40 }}
            onClick={() => onClickUpdateBiomass()}
          >
            {t("home.set_threshold")}
          </button>
          // <Typography
          //   sx={{
          //     fontSize: 18,
          //     fontWeight: "600",
          //     color: color,
          //     marginTop: 0.5,
          //   }}
          // >
          //   {t("ponds.click_here_to_see")}
          // </Typography>
        )}

        {isUpdate && (
          <button
            className="ui primary button"
            style={{ width: "auto", height: 40, marginTop: 10 }}
            onClick={() => onClickUpdateBiomass()}
          >
            {t("ponds.counting_records")}
          </button>
          // <Typography
          //   sx={{
          //     fontSize: 18,
          //     fontWeight: "600",
          //     color: color,
          //     marginTop: 0.5,
          //   }}
          //   onClick={() => onClickUpdateBiomass()}
          // >
          //   {t("ponds.click_here_to_update")}
          // </Typography>
        )}
      </div>
    </Card>
  );
}

export default DetailCard;
