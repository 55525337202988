import {
  deleteDoc,
  doc,
  getDocs,
  collection,
  getDoc,
  where,
  query,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../../config/firebase-config";
import { deleteObject, ref } from "firebase/storage";
import {
  FIRESTORE_ALARMS,
  FIRESTORE_BATCH,
  FIRESTORE_COUNTERS,
  FIRESTORE_DEVICE_POOL,
  FIRESTORE_FARMS,
  FIRESTORE_INVOICES,
  FIRESTORE_LICENSES,
  FIRESTORE_PONDS,
  FIRESTORE_POND_CYCLE,
  FIRESTORE_POND_RECORD,
  FIRESTORE_TRANSFER_HISTORY,
  FIRESTORE_USERS,
} from "../config";
import { uuidv4 } from "../../common/utils";

export const deleteUserFromAdmin = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await pondRecordImageDelete(id);
      if (response) {
        const deletePromises = [
          deletePidData(FIRESTORE_PONDS, id),
          deletePidData(FIRESTORE_USERS, id),
          deleteDoc(doc(db, FIRESTORE_USERS, id)),
          deletePidData(FIRESTORE_ALARMS, id),
          deleteCountersRecords(id),
          deletePidData(FIRESTORE_DEVICE_POOL, id),
          deleteDoc(doc(db, FIRESTORE_FARMS, id)),
          deleteUidData(FIRESTORE_INVOICES, id),
          deletePidData(FIRESTORE_LICENSES, id),
          deletePidData(FIRESTORE_POND_CYCLE, id),
          deletePidData(FIRESTORE_POND_RECORD, id),
          deletePidData(FIRESTORE_TRANSFER_HISTORY, id),
        ];

        await Promise.all(deletePromises);
      }
      resolve("Successfully deleted");
    } catch (error) {
      reject(error);
    }
  });
};

const deleteCountersRecords = async (id) => {
  try {
    // Delete the document
    const docRef = doc(db, FIRESTORE_COUNTERS, id);
    await deleteDoc(docRef);

    // Delete all documents in the 'monthlyRecords' subcollection
    const monthlyRecordsQuery = query(collection(db, `${FIRESTORE_COUNTERS}/${id}/monthlyRecords`));
    const monthlyRecordsSnapshot = await getDocs(monthlyRecordsQuery);
    const deletePromises = monthlyRecordsSnapshot.docs.map(async (doc) => {
      await deleteDoc(doc.ref);
    });
    await Promise.all(deletePromises);

    return "Successfully deleted";
  } catch (error) {
    throw error;
  }
};

const pondRecordImageDelete = async (id) => {
  try {
    const citiesRef = collection(db, FIRESTORE_POND_RECORD);
    const q = query(citiesRef, where("pId", "==", id));
    const querySnapshotNew = await getDocs(q);

    const deletePromises = querySnapshotNew.docs.map(async (doc) => {
      const recordId = doc.data().recordId;

      const topStorageRef = ref(storage, `pondRecords/pond_${recordId}_top`);
      const sideStorageRef = ref(storage, `pondRecords/pond_${recordId}_side`);
      const weightStorageRef = ref(
        storage,
        `pondRecords/pond_${recordId}_weight`
      );

      try {
        await deleteObject(topStorageRef);
      } catch (error) {
        console.log("error: ", error);
      }

      try {
        await deleteObject(sideStorageRef);
      } catch (error) {
        console.log("error: ", error);
      }

      try {
        await deleteObject(weightStorageRef);
      } catch (error) {
        console.log("error: ", error);
      }
    });

    await Promise.all(deletePromises);

    return true;
  } catch (error) {
    throw error;
  }
};

const deletePidData = (name, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const querySnapshot = await getDocs(collection(db, name));
      querySnapshot.forEach(async (doc) => {
        if (doc?.data()?.pId?.startsWith(id)) {
          await deleteDoc(doc.ref);
        }
      });
      return resolve("Successfully deleted");
    } catch (error) {
      return reject(error);
    }
  });
};

const deleteUidData = (name, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const querySnapshot = await getDocs(collection(db, name));
      querySnapshot.forEach(async (doc) => {
        if (doc.data().uid?.startsWith(id)) {
          await deleteDoc(doc.ref);
        }
      });
      return resolve("Successfully deleted");
    } catch (error) {
      return reject(error);
    }
  });
};

export const addInviteHistory = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await getInviteHistoryByEmail(data.email)
      if (user) {
        return resolve('user already exist');
      }
      let uuid = uuidv4()
      const docFarmRef = doc(db, "InviteHistory", `${uuid}`);
      await setDoc(docFarmRef, data);
      return resolve(uuid);
    } catch (error) {
      return reject(error);
    }
  });
}
export const getInviteHistoryById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "InviteHistory", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getInviteHistoryByEmail = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const inviteHistory = collection(db, 'InviteHistory');
      const q = query(inviteHistory, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      // Extract data from documents
      const inviteHistoryData = querySnapshot.docs.map(doc => doc.data());

      if (inviteHistoryData.length > 0) {
        resolve(inviteHistoryData);
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
};

