import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../style/auth/SignUp.css";
import colors from "../../common/colors";
import { useNavigate } from "react-router-dom";
import imagePath from "../../common/imagePath";
import { singUpUser } from "../../database/auth/signUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Error } from "../../common/CustomToast";
import { useTranslation } from "react-i18next";
import { Autocomplete, LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { TIMEZONE } from "../../common/commonList";

function SignUp() {
  const { t } = useTranslation();
  const [user, setUser] = useState({
    fullname: "",
    jobTitle: "",
    comName: "",
    email: "",
    password: "",
    phone: "",
    confirmPassword: "",
    timezone: "",
  });
  const [isNameErr, setIsNameErr] = useState(false);
  const [isjobTitleErr, setIsjobTitleErr] = useState(false);
  const [iscomNameErr, setIscomNameErr] = useState(false);
  const [isTimezoneErr, setIsTimezoneErr] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [isConfirmPasswordErr, setIsConfirmPasswordErr] = useState(false);
  const [isPhoneErr, setIsPhoneErr] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const timeZoneList = TIMEZONE;

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let passwordRegex = /^(?=.*).{6,}$/;
    let phoneRegex = /^\d{10,12}$/;
    let fullNameRegex = /^[a-zA-Z\s]+/gi;
    if (user.fullname === "") {
      setIsDisable(false);
      return setIsNameErr(true);
    } else if (!fullNameRegex.test(user.fullname)) {
      setIsDisable(false);
      return setIsNameErr(true);
    }
    if (user.jobTitle === "") {
      setIsDisable(false);
      return setIsjobTitleErr(true);
    }
    if (user.comName === "") {
      setIsDisable(false);
      return setIscomNameErr(true);
    }
    if (user.timezone === "") {
      setIsDisable(false);
      return setIsTimezoneErr(true);
    }
    if (user.email === "") {
      setIsDisable(false);
      return setIsEmailErr(true);
    } else if (!emailRegex.test(user.email)) {
      setIsDisable(false);
      return setIsEmailErr(true);
    }
    if (user.phone === "") {
      setIsDisable(false);
      return setIsPhoneErr(true);
    } else if (!user.phone?.startsWith("+")) {
      setIsDisable(false);
      return setIsPhoneErr(true);
    }
    if (user.password === "") {
      setIsDisable(false);
      return setIsPasswordErr(true);
    } else if (!passwordRegex.test(user.password)) {
      setIsDisable(false);
      return setIsPasswordErr(true);
    }
    if (user.confirmPassword === "") {
      setIsDisable(false);
      return setIsConfirmPasswordErr(true);
    } else if (!passwordRegex.test(user.confirmPassword)) {
      setIsDisable(false);
      return setIsConfirmPasswordErr(true);
    }
    if (user.password !== user.confirmPassword) {
      setIsDisable(false);
      return setIsConfirmPasswordErr(true);
    }
    try {
      // setIsLoading(true);
      const data = {
        fullName: user.fullname,
        jobTitle: user.jobTitle,
        comName: user.comName,
        email: user.email,
        phone: user.phone,
        timezone: user.timezone,
      };
      await singUpUser(user.email, user.password, data);
      setIsDisable(false);
      navigation("/login");
    } catch (error) {
      if (error.code !== "" && error.code === "auth/email-already-in-use") {
        Error(t("userManagement.this_email_is_already_in_use"));
      } else if (error.message !== "") {
      }
      setIsDisable(false);
      setIsLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "fullname" && isNameErr) {
      setIsNameErr(false);
    } else if (name === "jobTitle" && isjobTitleErr) {
      setIsjobTitleErr(false);
    } else if (name === "comName" && iscomNameErr) {
      setIscomNameErr(false);
    } else if (name === "timezone" && isTimezoneErr) {
      setIsTimezoneErr(false);
    } else if (name === "email" && isEmailErr) {
      setIsEmailErr(false);
    } else if (name === "password" && isPasswordErr) {
      setIsPasswordErr(false);
    } else if (name === "confirmPassword" && isConfirmPasswordErr) {
      setIsPasswordErr(false);
    } else if (name === "phone" && isPhoneErr) {
      setIsPhoneErr(false);
    } else if (name === "checkbox") {
      setIsDisable(!isDisable);
    }
    setUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const autoValue = (value) => {
    const selectedObj =
    timeZoneList && timeZoneList.find((item) => item.text === value);
    return selectedObj;
  };

  const nameClassName = `field ${isNameErr ? "error" : ""}`;
  const jobClassName = `field ${isjobTitleErr ? "error" : ""}`;
  const comNameClassName = `field ${iscomNameErr ? "error" : ""}`;
  const timezoneClassName = `field autocomplete_country ${isTimezoneErr ? "error" : ""}`;
  const emailClassName = `field ${isEmailErr ? "error" : ""}`;
  const pwdClassName = `field ${isPasswordErr ? "error" : ""}`;
  const conPwdClassName = `field ${isConfirmPasswordErr ? "error" : ""}`;
  const phoneClassName = `field ${isPhoneErr ? "error" : ""}`;
  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100 m-auto">
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            // minWidth: window.innerWidth > 1100 ? 600 : 260,
            width: "100%",
            // maxWidth: 450,
          }}
          className="main-form-block"
        >
          <form className="ui form segment error">
            {/* ----- IMAGE ----- */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: 5,
              }}
              className="main-logo-block-css"
            >
              <img
                src={imagePath.main_logo}
                alt="logo"
                width="250"
                height="55"
                style={{ marginBottom: 10, borderRadius: 10 }}
              ></img>
              <h2 style={{ fontWeight: "600" }}>{t("login.sign_up")}</h2>
            </div>

            <div className="signUp_form_row">
              {/* ----- FULL NAME ----- */}
              <div className={nameClassName}>
                <label>{t("profile.full_name")}</label>
                <input
                  onChange={onInputChange}
                  value={user.fullname}
                  type="text"
                  name="fullname"
                  placeholder={t("profile.enter_full_name")}
                  required
                />
                {isNameErr && (
                  <div className="ui pointing red basic label">
                    {user.fullname === ""
                      ? t("profile.please_fill_name_field")
                      : t("profile.invalid_full_name")}
                  </div>
                )}
              </div>

              {/* ----- JOB TITLE ----- */}
              <div className={jobClassName}>
                <label>{t("profile.job_title")}</label>
                <input
                  onChange={onInputChange}
                  value={user.jobTitle}
                  type="text"
                  name="jobTitle"
                  placeholder={t("profile.enter_job_title")}
                  required
                />
                {isjobTitleErr && (
                  <div className="ui pointing red basic label">
                    {user.jobTitle === ""
                      ? t("profile.please_fill_job_title_field")
                      : t("profile.invalid_job_title")}
                  </div>
                )}
              </div>

              {/* ----- COMPANY NAME ----- */}
              <div className={comNameClassName}>
                <label>{t("profile.company_name")}</label>
                <input
                  onChange={onInputChange}
                  value={user.comName}
                  type="text"
                  name="comName"
                  placeholder={t("profile.enter_company_name")}
                  required
                />
                {iscomNameErr && (
                  <div className="ui pointing red basic label">
                    {user.comName === ""
                      ? t("profile.please_fill_company_name_field")
                      : t("profile.invalid_company_name")}
                  </div>
                )}
              </div>

              {/* ----- EMAIL ----- */}
              <div className={emailClassName}>
                <label>{t("profile.email")}</label>
                <input
                  onChange={onInputChange}
                  value={user.email}
                  type="email"
                  name="email"
                  placeholder={t("profile.enter_email")}
                  required
                />
                {isEmailErr && (
                  <div className="ui pointing red basic label">
                    {user.email === ""
                        ? t("userManagement.email_msg_1")
                        : t("userManagement.email_msg_2")}
                  </div>
                )}
              </div>

              {/* ----- PHONE ----- */}
              <div className={phoneClassName}>
                <label>{t("profile.phone_number")}</label>
                <input
                  onChange={onInputChange}
                  value={user.phone}
                  type="tel"
                  name="phone"
                  placeholder={t("profile.enter_phone_number")}
                  required
                />
                {isPhoneErr && (
                  <div className="ui pointing red basic label">
                    {user.phone === ""
                      ? t("userManagement.phone_msg_1")
                      : t("userManagement.phone_msg_2")}
                  </div>
                )}
                <p className="">
                  {t("profile.put_area_code_in_front")}
                </p>
              </div>

              {/* ----- Timezone ----- */}
              <div className={timezoneClassName}>
                <label>{t("profile.timezone")}</label>
                <Autocomplete
                  options={timeZoneList}
                  getOptionLabel={(option) => option.text}
                  renderInput={(params) => (
                    <TextField {...params}/>
                  )}
                  value={autoValue(user.timezone)}
                  onChange={(event, value) => {
                    if (value) {
                      onInputChange({
                        target: {
                          value: value.utc[0],
                          name: "timezone",
                        },
                      });
                    }else {
                      onInputChange({  
                        target: {
                          value: "",  
                          name: "timezone",
                        },
                      });
                    }
                  }}
                />
                {isTimezoneErr && (
                  <div className="ui pointing red basic label">
                    {user.timezone === ""
                      ? t("profile.please_fill_timezone_field")
                      : ""}
                  </div>
                )}
              </div>

              {/* ----- PASSWORD ----- */}
              <div className={pwdClassName}>
                <label>{t("userManagement.password")}</label>
                <input
                  onChange={onInputChange}
                  value={user.password}
                  type="password"
                  name="password"
                  placeholder={t("userManagement.enter_password")}
                  autoComplete="off"
                  required
                />
                {isPasswordErr && (
                  <div className="ui pointing red basic label">
                    {Number(user.password.length) > 0
                        ? Number(user.password.length) < 6
                          ? t("userManagement.password_msg_1")
                          : t("userManagement.password_msg_2")
                        : t("userManagement.password_msg_3")}
                  </div>
                )}
              </div>

              {/* ----- confirm password ----- */}
              <div className={conPwdClassName}>
                <label>{t("userManagement.confirm_password")}</label>
                <input
                  onChange={onInputChange}
                  value={user.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  placeholder={t("userManagement.enter_confirm_password")}
                  autoComplete="off"
                  required
                />
                {isConfirmPasswordErr && (
                  <div className="ui pointing red basic label">
                     {Number(user.confirmPassword.length) > 0
                        ? Number(user.confirmPassword.length) < 6
                          ? t("userManagement.confirm_msg_1")
                          : user.confirmPassword !== user.password
                            ? t("userManagement.confirm_msg_2")
                            : t("userManagement.confirm_msg_3")
                        : t("userManagement.confirm_msg_4")}
                  </div>
                )}
              </div>
            </div>

            <div className="signuo_privacy_notice_block">
              <div>
                <input
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  value={!isDisable}
                  onChange={onInputChange}
                ></input>
                <p>
                  {t("i_agree_to_the")}
                  <a
                    href="https://www.sincereaqua.com/terms-and-conditions"
                    target="/"
                  >
                    {t("terms_of_Service")}
                  </a>
                </p>
              </div>
              <p>
                {t("we_will_process_your_personal")}
                <a href="https://www.sincereaqua.com/privacy-policy" target="/">
                  {t("privacy_notice")}
                </a>
              </p>
            </div>

            {/* ----- SIGN-UP BUTTON ----- */}
            <div className="loading_button">
              <LoadingButton
                onClick={onSubmit}
                className="ui primary submit button"
                style={{ width: "100%", backgroundColor: colors.slatery_blue }}
                type="submit"
                loadingPosition="start"
                loading={isLoading}
                disabled={isDisable}
              >
                {t("login.sign_up")}
              </LoadingButton>
            </div>

            {/* ----- MOVE LOGIN PAGE ----- */}
            <div className="move_login_container move_register_container">
              <p className="move_login_text move_register_text">
                {t("login.got_an_account")}
                <span
                  className="move_login move_register"
                  onClick={() => navigation("/login")}
                >
                  &nbsp;{t("login.click_here_to_login")}
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
